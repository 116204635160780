import UserBadge from 'components/navigation/userBadge/UserBadge';
import NavList from 'components/navigation/navList/NavList';
import Drawer from 'components/layout/drawer/Drawer';
import { AgoraCareLogo } from '@agora-care/ui-components';
import style from './AgoraSidebar.module.scss';

function AgoraSidebar() {
  return (
    <Drawer>
      <div className={style.logo}>
        <AgoraCareLogo />
      </div>
      <UserBadge />
      <NavList />
    </Drawer>
  );
}

export default AgoraSidebar;
