import { User, UserManager } from 'oidc-client-ts';
import { IDENTITY_CONFIG } from '../constants/authConst';

type Institution = {
  institution?: string,
};

export default class AuthService {
  userManager: UserManager;

  user: User & Institution | null;

  constructor() {
    this.userManager = new UserManager(IDENTITY_CONFIG);
    this.user = null;

    this.userManager.events.addSilentRenewError(() => {
      this.login();
    });

    this.userManager.events.addUserLoaded((user) => {
      this.user = user;
    });

    this.userManager.events.addAccessTokenExpired(() => {
      this.logout();
    });

    this.userManager.events.addUserSignedOut(() => {
      this.login();
    });
  }

  isLoggedIn(): boolean {
    return this.user !== null && this.user.access_token.length > 0 && !this.user.expired;
  }

  loadUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      this.userManager.getUser()
        .then((user) => {
          this.user = user;
          resolve(user);
        }).catch((err) => {
          reject(err);
        });
    });
  }

  getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  getAuthorizationHeaderValue(): string {
    if (this.user !== null) {
      return `${this.user.token_type} ${this.user.access_token}`;
    }
    return '';
  }

  getAccessToken(): string {
    if (this.user !== null) {
      return this.user.access_token;
    }
    return '';
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
