import PropTypes from 'prop-types';
import styles from './content.module.scss';

type Props = {
  className: string,
  children: JSX.Element,
}

function Content(props: Props): JSX.Element {
  const { className, children } = props;

  return (
    <div className={`${className} ${styles.content}`}>
      {children}
    </div>
  );
}

Content.defaultProps = {
  className: '',
};

Content.propTypes = {
  className: PropTypes.string,
};

export default Content;
