import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import verifyEmail, { ValidationEmail } from '../../api/emailValidator';
import InputFields from './InputFields';
import { validateEmail } from '../../utils/valid';
import InputEmailVerdict from './InputEmailVerdict';
import styles from './InputEmail.module.scss';

interface Props {
  label: string,
  id: string,
  value: string,
  errorMessage: string,
  onChange(value: string): void,
  onDisabled(disabled: boolean): void,
  required: boolean,
  disabled: boolean,
  deactivatable: boolean,
  className: string,
}

function InputEmail(props: Props): JSX.Element {
  const {
    errorMessage, id, label, value, onChange, onDisabled, required, disabled, deactivatable, className,
  } = props;
  const { t } = useTranslation();

  const [verdict, setVerdict] = useState<ValidationEmail | null>(null);
  const [loadingVerdict, setLoadingVerdict] = useState(false);

  const handleFocusOut = async () => {
    try {
      if (value.length > 0 && validateEmail(value)) {
        if (window.authService !== undefined) {
          const token = window.authService.getAccessToken();
          if (token !== undefined) {
            setLoadingVerdict(true);
            const response = await verifyEmail(value, token);
            setVerdict(response);
            setLoadingVerdict(false);
          }
        }
      } else {
        setVerdict({ verdict: 'Invalid' });
      }
    } catch {
      setLoadingVerdict(false);
      setVerdict(null);
    }
  };

  const handleDisabled = (newDisabled: boolean) => {
    if (onDisabled !== undefined) {
      onDisabled(newDisabled);
    }
  };

  useEffect(() => {
    setVerdict(null);
  }, [value]);

  return (
    <div
      className={`${styles.wrapper} ${className}`}
    >
      <InputFields
        label={label}
        id={id}
        value={value}
        type='email'
        errorMessage={errorMessage}
        onChange={onChange}
        onFocusOut={handleFocusOut}
        required={required}
        deactivatable={deactivatable}
        deactivatableText={t('inputemail.deactivatable')}
        disabled={disabled}
        onDisabled={handleDisabled}
        isLoading={loadingVerdict}
      />
      {
        !disabled && (
          <>
            {
              (
                (verdict === null
                && loadingVerdict === true)
              ) && (
              <Message
                type='info'
                message={t('inputemail.validationinprogress')}
              />
              )
            }
            {
              (
                verdict !== null
              ) && (
                <InputEmailVerdict
                  verdict={verdict}
                />
              )
            }
          </>
        )
      }
    </div>
  );
}

InputEmail.defaultProps = {
  onChange: undefined,
  value: '',
  errorMessage: '',
  required: false,
  disabled: false,
  deactivatable: false,
  onDisabled: undefined,
  className: '',
};

InputEmail.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  deactivatable: PropTypes.bool,
  onDisabled: PropTypes.func,
  className: PropTypes.string,
};

export default InputEmail;
