import axios from 'axios';

const url = process.env.REACT_APP_EMAIL_VALIDATOR;
const emailValidatorClient = axios.create({ baseURL: url });

export type ValidationEmail = {
  verdict: 'Valid' | 'Risky' | 'Invalid'
  suggestions?: string[]
}

export const verifyEmail = async (email: string, token: string): Promise<ValidationEmail> => {
  const data = {
    email,
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await emailValidatorClient.post('/institution/signup/verify', data, { headers });
  return response.data;
};

export default verifyEmail;
