import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import Loader from 'components/loader/Loader';
import InputCheckBox from './InputCheckBox';
import styles from './InputFields.module.scss';

interface Props {
  label: string,
  id: string,
  value: string,
  errorMessage: string,
  onChange(value: string): void,
  onFocusOut(value: string): void,
  type: 'input' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'date',
  required: boolean,
  disabled: boolean,
  deactivatable: boolean,
  deactivatableText: string,
  onDisabled(disabled: boolean): void,
  className: string,
  isLoading: boolean,
}

function InputFields(props: Props): JSX.Element {
  const {
    errorMessage, id, label, value, onChange, onFocusOut, type, required, disabled, deactivatable, deactivatableText, onDisabled, className, isLoading,
  } = props;
  const [focusOut, setFocusOut] = useState<boolean>(false);
  const [pastedValue, setPasted] = useState<boolean>(false);
  const { t } = useTranslation();

  const requiredMessage: string = t('input.requiredfield');

  useEffect(() => {
    if (value.length > 0) {
      setFocusOut(false);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange !== undefined && !pastedValue) {
      onChange(event.target.value);
    }
    if (onChange !== undefined && pastedValue) {
      onChange(event.target.value.trim());
      setPasted(false);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    if (onChange !== undefined && event.clipboardData !== null) {
      setPasted(true);
    }
  };

  const handleBlur = (): void => {
    setFocusOut(true);
  };

  const handleDisabled = (currentDisabled: boolean): void => {
    if (onDisabled !== undefined) {
      onDisabled(currentDisabled);
    }
  };

  useEffect(() => {
    if (focusOut === true && onFocusOut !== undefined) {
      onFocusOut(value);
    }
  }, [focusOut]);

  return (
    <div className={`${styles.wrapper} ${className} ${!disabled && errorMessage.length > 0 && styles.error}`}>
      {
        deactivatable && (
          <InputCheckBox
            id={`input-array-checkbox-${id}`}
            value={deactivatableText}
            checked={disabled}
            onChange={(checked) => {
              handleDisabled(checked);
            }}
            className={styles.desactivatable}
          />
        )
      }
      <input
        type={type}
        id={id}
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
        onBlur={handleBlur}
        className={styles.input}
        autoComplete='off'
        data-testid='input-fields'
        disabled={disabled}
      />
      {isLoading && (<Loader className={styles.loader} size={16} />)}
      <label
        htmlFor={id}
        className={styles.label}
        data-testid='label-fields'
      >
        {label}
      </label>
      {
        !disabled && (
          <>
            {
              (required && focusOut && value.length === 0 && errorMessage.length === 0) && (
                <Message
                  type='warning'
                  message={requiredMessage}
                  className={styles.message}
                />
              )
            }
            {
              errorMessage && (
                <Message
                  type='error'
                  message={errorMessage}
                  className={styles.message}
                />
              )
            }
          </>
        )
      }
    </div>
  );
}

InputFields.defaultProps = {
  onChange: undefined,
  onFocusOut: undefined,
  value: '',
  errorMessage: '',
  type: 'text',
  required: false,
  disabled: false,
  deactivatable: false,
  deactivatableText: '',
  onDisabled: undefined,
  className: '',
  isLoading: false,
};

InputFields.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onFocusOut: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  deactivatable: PropTypes.bool,
  deactivatableText: PropTypes.string,
  onDisabled: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default InputFields;
