import PropTypes, { InferProps } from 'prop-types';
import { FormEvent } from 'react';
import styles from './Form.module.scss';

function Form(props: InferProps<typeof Form.propTypes>) {
  const { children, onSubmit } = props;

  const handleOnSubmit = (e: FormEvent) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={handleOnSubmit}
    >
      {children}
    </form>
  );
}

Form.defaultProps = {
  onSubmit: undefined,
};

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSubmit: PropTypes.func,
};

export default Form;
