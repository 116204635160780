import { atom, selector } from 'recoil';

export const publicJWKState = atom<JsonWebKey | undefined>({
  key: 'PublicJWK',
  default: undefined,
});

export const privateKeyState = atom<CryptoKey | undefined>({
  key: 'PrivateKey',
  default: undefined,
});

export const selectorPublicJWKState = selector({
  key: 'GeneratedPublicJWK',
  get: ({ get }) => get(publicJWKState),
});

export const selectorPrivateKeyState = selector({
  key: 'GeneratedPrivateKey',
  get: ({ get }) => get(privateKeyState),
});
