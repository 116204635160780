import React, {
  useMemo, useEffect, useState, FormEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { InferProps } from 'prop-types';
import { useRecoilValue } from 'recoil';
import Form from 'components/layout/form/Form';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import Stack from 'components/layout/stack/Stack';
import Message from 'components/message/Message';
import InputMultiSelect from 'components/input/InputMultiSelect';
import InputCountry from 'components/input/InputCountry';
import SelectValidationType from 'components/input/SelectValidationType';
import Popup from 'components/popup/Popup';
import { Button } from '@agora-care/ui-components';
import { validateEmail, validatePhoneNumber } from '../../utils/valid';
import styles from './Registration.module.scss';
import InputFields from '../input/InputFields';
import PhoneInputFields from '../input/PhoneInputFields';
import { UserRegistration } from './UserRegistration';
import InputArray from '../input/InputArray';
import ContentButton from '../content/ContentButton';
import Title from '../content/Title';
import InputContent from '../content/InputContent';
import InputDate from '../input/InputDate';
import EnrollmentForm from '../../services/EnrollmentForm';
import SelectLang from '../input/SelectLang';
import InputEmail from '../input/InputEmail';
import { dateToDateFormat } from '../../utils/date';
import { minDate } from '../../constants/birthdateConst';
import { selectorFiltererInstitutionsState, selectorInstitutionsObjectState } from '../../state/institutions';
import { seletorUserToRegisterIsStaffOnly } from '../../state/userToRegister';
import InputCheckBox from '../input/InputCheckBox';
import { UserRegistrationRequest, register } from '../../api/registrationDelegate';
import ADDRESS_NO_EMAIL from '../../constants/email';
import {
  UserRegistrationPaperlessRequest, generateMailingAddressISO, registerPaperless,
} from '../../api/user';
import SearchByPID from './SearchByPID';
import { UserInformation } from '../../api/patientDemographicQuery';

type DisabledKey = 'email' | 'pids';

export type DisabledKeys = Array<DisabledKey>;

interface InputUserError {
  firstName: string,
  lastName: string,
  birthDate: string,
  pids: string,
  email: string,
  mobileNumber: string,
  street: string,
  city: string,
  country: string,
  zipCode: string,
  validationType: string,
}

function Registration(props: InferProps<typeof Registration.propTypes>) {
  const {
    agoraID, institutionID, user, userEnroller, currentDisabledKeys, onCancel, onSubmit, onSubmitPaperless, onSubmitFullPaperless, isPaperlessWithoutAgoraID,
  } = props;
  const { t, i18n } = useTranslation();
  const institutions = useRecoilValue(selectorFiltererInstitutionsState);
  const allInstitutions = useRecoilValue(selectorInstitutionsObjectState);
  const [inputUser, setInputUser] = useState<UserRegistration>({ ...user } as UserRegistration);
  const [error, setError] = useState<InputUserError>({
    firstName: '',
    lastName: '',
    birthDate: '',
    pids: '',
    email: '',
    mobileNumber: '',
    street: '',
    city: '',
    country: '',
    zipCode: '',
    validationType: '',
  });
  const [shiftDown, setShiftDown] = useState<boolean>(false);
  const [disabledKeys, setDisabledKeys] = useState<DisabledKeys>(currentDisabledKeys as DisabledKeys);
  const [enablePaperless, setEnablePaperless] = useState(false);
  const [loadingPaperless, setLoadingPaperless] = useState(false);
  const [errorPaperless, setErrorPaperless] = useState(false);
  const userIsStaffOnly = useRecoilValue(seletorUserToRegisterIsStaffOnly);
  const [popupConfirmation, setPopupConfirmation] = useState({
    isOpen: false,
    title: '',
    text: '',
  });

  const requiredMessage = t('user.requiredfield');
  const enrollmentForm = useMemo(() => new EnrollmentForm(inputUser.locale), [inputUser.locale]);

  const checkErrorLenght = (key: keyof UserRegistration, value: string | string[]): boolean => {
    if (value.length === 0) {
      setError((currentError) => ({ ...currentError, [key]: requiredMessage }));
      return true;
    }
    setError((currentError) => ({ ...currentError, [key]: '' }));
    return false;
  };

  const checkErrorEmail = (email: string): boolean => {
    if (email.length > 0 && !validateEmail(email)) {
      setError((currentError) => ({ ...currentError, email: t('user.invalidemail') }));
      return true;
    }
    return checkErrorLenght('email', email);
  };

  const checkErrorMobileNumber = (mobileNumber: string): boolean => {
    if (!validatePhoneNumber(mobileNumber)) {
      setError((currentError) => ({ ...currentError, mobileNumber: t('user.invalidmobilenumber') }));
      return true;
    }
    return checkErrorLenght('mobileNumber', mobileNumber);
  };

  const checkErrorBirthdate = (birthDate: string) => {
    const date = new Date(birthDate);

    if (date < minDate) {
      const minDateReadable = dateToDateFormat(minDate, i18n.language);
      setError((currentError) => ({ ...currentError, birthDate: t('user.invalidbirthdatetoosmall', { birthdate: minDateReadable }) }));
      return true;
    }

    if (date > new Date()) {
      setError((currentError) => ({ ...currentError, birthDate: t('user.invalidbirthdatetoobig') }));
      return true;
    }
    return false;
  };

  const checkFields = async () => (
    !(await Promise.all([
      checkErrorLenght('firstName', inputUser.firstName),
      checkErrorLenght('lastName', inputUser.lastName),
      checkErrorLenght('pids', inputUser.pids)
        && !disabledKeys.includes('pids')
        && !userIsStaffOnly,
      checkErrorLenght('birthDate', inputUser.birthDate),
      checkErrorLenght('email', inputUser.email) && !disabledKeys.includes('email'),
      checkErrorLenght('mobileNumber', inputUser.mobileNumber),
      checkErrorLenght('street', inputUser.street),
      checkErrorLenght('city', inputUser.city),
      checkErrorLenght('country', inputUser.country),
      checkErrorLenght('zipCode', inputUser.zipCode),
      checkErrorEmail(inputUser.email) && !disabledKeys.includes('email'),
      checkErrorMobileNumber(inputUser.mobileNumber),
      checkErrorBirthdate(inputUser.birthDate),
      checkErrorLenght('validationType', inputUser.validationType),
    ])).reduce((previousValue, currentValue) => (
      previousValue || currentValue
    ), false)
  );

  const retrieveUser = () => {
    const { institutionsPatient } = inputUser;
    if (
      window.authService?.user?.profile.institution !== undefined
      && !institutionsPatient.some((institution) => institution.institutionID === window.authService?.user?.profile.institution)
    ) {
      institutionsPatient.unshift({
        institutionName: allInstitutions[window.authService.user.profile.institution as string].name,
        institutionID: window.authService.user.profile.institution as string,
      });
    }

    return { ...inputUser, institutionsPatient };
  };

  const handlePrint = () => {
    const userRetrieved = retrieveUser();
    const institutionsAffiliation = [
      ...userRetrieved.institutionsPatient.map((institution) => (
        institution.institutionName
      )),
    ];

    enrollmentForm.createPDF({
      ...inputUser,
      agoraID,
      userEnroller,
      institutions: institutionsAffiliation,
    });
  };

  const handlePaperless = async () => {
    try {
      setErrorPaperless(false);
      setLoadingPaperless(true);
      if (window.authService === undefined) {
        return;
      }

      let userRegistration: UserRegistrationRequest = {
        first_name: inputUser.firstName.trim(),
        last_name: inputUser.lastName.trim(),
        date_of_birth: inputUser.birthDate,
        mobile_number: inputUser.mobileNumber,
        email: disabledKeys.includes('email') ? ADDRESS_NO_EMAIL : inputUser.email,
        mailing_address: generateMailingAddressISO(inputUser).trim(),
        institutions: [...inputUser.institutionsPatient.map((institution) => institution.institutionID), institutionID],
        locale: inputUser.locale,
        validation_type: inputUser.validationType,
      };

      if (!disabledKeys.includes('pids') && !userIsStaffOnly) {
        userRegistration = {
          ...userRegistration,
          institutionsPIDs: [{
            institution: institutionID,
            pids: inputUser.pids,
          }],
        };
      }

      await register(
        userRegistration,
        agoraID,
        institutionID,
        window.authService.getAccessToken(),
      );

      if (
        onSubmitPaperless !== undefined
        && onSubmitPaperless !== null
      ) {
        onSubmitPaperless();
      }
      setLoadingPaperless(false);
    } catch {
      setErrorPaperless(true);
      setLoadingPaperless(false);
    }
  };

  const handleSubmitPaperlessWithoutAgoraID = async () => {
    try {
      setErrorPaperless(false);
      setLoadingPaperless(true);
      if (window.authService === undefined) {
        return;
      }

      let userRegistration: UserRegistrationPaperlessRequest = {
        first_name: inputUser.firstName.trim(),
        last_name: inputUser.lastName.trim(),
        date_of_birth: inputUser.birthDate,
        mobile_number: inputUser.mobileNumber,
        email: disabledKeys.includes('email') ? ADDRESS_NO_EMAIL : inputUser.email,
        mailing_address: generateMailingAddressISO(inputUser).trim(),
        institutions: [...inputUser.institutionsPatient.map((institution) => institution.institutionID), institutionID],
        locale: inputUser.locale,
        validation_type: inputUser.validationType,
      };

      if (!disabledKeys.includes('pids') && !userIsStaffOnly) {
        userRegistration = {
          ...userRegistration,
          pids: inputUser.pids,
        };
      }

      const agoraIDRegistered = (await registerPaperless(
        userRegistration,
        institutionID,
        window.authService.getAccessToken(),
      )).agoraID;

      setPopupConfirmation({
        isOpen: true,
        title: t('registration.popupconfirmation.title'),
        text: t('registration.popupconfirmation.message', { agoraId: agoraIDRegistered }),
      });

      setLoadingPaperless(false);
    } catch {
      setErrorPaperless(true);
      setLoadingPaperless(false);
    }
  };

  const handleClosePopup = () => {
    setPopupConfirmation({
      isOpen: false,
      title: '',
      text: '',
    });

    if (
      isPaperlessWithoutAgoraID
      && onSubmitFullPaperless !== undefined
      && onSubmitFullPaperless !== null
    ) {
      onSubmitFullPaperless();
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setError({
      firstName: '',
      lastName: '',
      birthDate: '',
      pids: '',
      email: '',
      mobileNumber: '',
      street: '',
      city: '',
      country: '',
      zipCode: '',
      validationType: '',
    });

    if (!isPaperlessWithoutAgoraID) {
      setInputUser((stateInputUser) => ({
        ...stateInputUser,
        validationType: 'in_person',
      }));
    }

    const errorOccured: boolean = !(await checkFields());

    if ((
      errorOccured === false
      || shiftDown
    )
      && !enablePaperless
      && !isPaperlessWithoutAgoraID
    ) {
      handlePrint();
    }

    if (
      errorOccured === false
      && onSubmit !== undefined
      && onSubmit !== null
      && !enablePaperless
      && !isPaperlessWithoutAgoraID
    ) {
      const userSubmitted = retrieveUser();
      onSubmit(userSubmitted, disabledKeys);
    }

    if (
      errorOccured === false
      && enablePaperless
    ) {
      handlePaperless();
    }

    if (
      errorOccured === false
      && isPaperlessWithoutAgoraID
    ) {
      handleSubmitPaperlessWithoutAgoraID();
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (
      onCancel !== undefined
      && onCancel !== null
    ) {
      onCancel(e);
    }
  };

  const formatUserInformationDateOfBirth = (dateOfBirth: string | undefined) => {
    if (dateOfBirth !== undefined) {
      const year = dateOfBirth.substring(0, 4);
      const month = dateOfBirth.substring(4, 6);
      const day = dateOfBirth.substring(6, 8);
      return `${year}-${month}-${day}`;
    }
    return '';
  };

  const hanldeFindingPID = (userInformation: UserInformation) => {
    const birthDate = formatUserInformationDateOfBirth(userInformation.date_of_birth);
    const mobileNumber = (() => {
      const pdqPhoneNumber = userInformation.telephone_number;

      if (
        pdqPhoneNumber !== undefined
        && validatePhoneNumber(pdqPhoneNumber)
      ) {
        return pdqPhoneNumber;
      }

      return '';
    })();

    const formattedUserInformation = {
      firstName: userInformation.patient_name?.given_name ?? '',
      lastName: userInformation.patient_name?.family_name ?? '',
      birthDate,
      pids: [userInformation.pid],
      street: userInformation.patient_address?.street_address?.street_or_mailing_address ?? '',
      city: userInformation.patient_address?.city ?? '',
      country: userInformation.patient_address?.country ?? '',
      zipCode: userInformation.patient_address?.postal_code ?? '',
      mobileNumber,
      email: userInformation.email_address ?? '',
    };

    setInputUser((currentInputUser) => ({
      ...currentInputUser,
      ...formattedUserInformation,
    }));
  };

  useEffect(() => {
    const onShiftDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftDown(true);
      }
    };

    const onShiftUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftDown(false);
      }
    };

    window.addEventListener('keydown', onShiftDown);
    window.addEventListener('keyup', onShiftUp);

    return () => {
      window.removeEventListener('keydown', onShiftDown);
      window.removeEventListener('keyup', onShiftUp);
    };
  }, []);

  const options = institutions.filter((institution) => {
    if (
      window.authService !== undefined
      && window.authService.user !== null
      && window.authService.user.profile.institution !== undefined
    ) {
      return institution.institutionID !== window.authService.user.profile.institution;
    }
    return false;
  }).map((institution) => ({
    label: institution.name,
    value: institution.institutionID,
    isDisabled: inputUser.institutionsPatient.length === 2,
  }));

  const defaultValues = inputUser.institutionsPatient.filter((institution) => {
    if (
      window.authService !== undefined
      && window.authService.user !== null
      && window.authService.user.profile.institution !== undefined
    ) {
      return institution.institutionID !== window.authService.user.profile.institution;
    }
    return false;
  }).map((institution) => ({
    label: institution.institutionName,
    value: institution.institutionID,
  }));

  return (
    <>
      <div className={styles.wrapper}>
        <CardWrapper>
          <Form onSubmit={handleSubmit}>
            <Title
              title={userIsStaffOnly ? t('staff.enrollment') : t('user.enrollment')}
            />
            <SearchByPID
              className={styles.searchByPID}
              onFinding={hanldeFindingPID}
            />
            <InputContent>
              <>
                <div>{t('user.agoracareid', { agora_care_id: agoraID })}</div>
                <Stack numberOfColumns='two-columns' width='medium'>
                  <InputFields
                    label={t('user.firstname')}
                    id='first_name'
                    value={inputUser.firstName}
                    errorMessage={error.firstName}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, firstName: value }));
                      setError((currentError) => ({ ...currentError, firstName: '' }));
                    }}
                    required
                  />
                  <InputFields
                    label={t('user.lastname')}
                    id='last_name'
                    value={inputUser.lastName}
                    errorMessage={error.lastName}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, lastName: value }));
                      setError((currentError) => ({ ...currentError, lastName: '' }));
                    }}
                    required
                  />
                </Stack>
                <Stack numberOfColumns='two-columns' width='medium'>
                  <InputDate
                    label={t('user.dateofbirth')}
                    id='date_of_birth'
                    value={inputUser.birthDate}
                    errorMessage={error.birthDate}
                    onChange={(birthDate) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, birthDate }));
                      setError((currentError) => ({ ...currentError, birthDate: '' }));
                    }}
                    required
                  />
                </Stack>
                <Stack numberOfColumns='two-columns' width='medium'>
                  <InputEmail
                    label={t('user.email')}
                    id='email'
                    value={inputUser.email}
                    errorMessage={error.email}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, email: value }));
                      setError((currentError) => ({ ...currentError, email: '' }));
                    }}
                    deactivatable
                    disabled={disabledKeys.includes('email')}
                    onDisabled={(disabled) => {
                      if (!disabled) {
                        setDisabledKeys((current) => current.filter((value) => value !== 'email'));
                      } else {
                        setDisabledKeys((current) => {
                          if (current.includes('email')) {
                            return current;
                          }
                          return [
                            ...current,
                            'email',
                          ];
                        });
                        setInputUser((stateInputUser) => ({ ...stateInputUser, email: '' }));
                      }
                    }}
                    required
                  />
                  <PhoneInputFields
                    label={t('user.mobilenumber')}
                    id='mobile_number'
                    value={inputUser.mobileNumber}
                    errorMessage={error.mobileNumber}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, mobileNumber: value }));
                      setError((currentError) => ({ ...currentError, mobileNumber: '' }));
                    }}
                    required
                  />
                </Stack>
                <Stack>
                  <InputFields
                    label={t('user.street')}
                    id='street'
                    value={inputUser.street}
                    errorMessage={error.street}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, street: value }));
                      setError((currentError) => ({ ...currentError, street: '' }));
                    }}
                    required
                  />
                </Stack>
                <Stack numberOfColumns='three-columns' width='medium'>
                  <InputFields
                    label={t('registration.zipcode')}
                    id='zip-code'
                    value={inputUser.zipCode}
                    errorMessage={error.zipCode}
                    type='tel'
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, zipCode: value }));
                      setError((currentError) => ({ ...currentError, zipCode: '' }));
                    }}
                    required
                  />
                  <InputFields
                    label={t('registration.city')}
                    id='city'
                    value={inputUser.city}
                    errorMessage={error.city}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, city: value }));
                      setError((currentError) => ({ ...currentError, city: '' }));
                    }}
                    required
                  />
                  <InputCountry
                    label={t('user.country')}
                    id='country'
                    value={inputUser.country}
                    errorMessage={error.country}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, country: value }));
                      setError((currentError) => ({ ...currentError, country: '' }));
                    }}
                    required
                  />
                </Stack>
                <Stack numberOfColumns='two-columns' width='medium'>
                  <SelectLang
                    label='Langue Utilisateur'
                    id='select-user-lang'
                    value={inputUser.locale}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, locale: value }));
                    }}
                  />
                </Stack>
                {
                !userIsStaffOnly && (
                  <>
                    <Stack>
                      <InputArray
                        label={t('user.pid')}
                        id='pid'
                        errorMessage={error.pids}
                        values={inputUser.pids}
                        onUpdate={(pids) => {
                          setInputUser((stateInputUser) => ({ ...stateInputUser, pids }));
                          setError((currentError) => ({ ...currentError, pids: '' }));
                        }}
                        deactivatable
                        deactivatableText={t('registration.deactivatablePIDs')}
                        disabled={disabledKeys.includes('pids')}
                        onDisabled={(disabled) => {
                          if (!disabled) {
                            setDisabledKeys((current) => current.filter((value) => value !== 'pids'));
                          } else {
                            setDisabledKeys((current) => {
                              if (current.includes('pids')) {
                                return current;
                              }
                              return [
                                ...current,
                                'pids',
                              ];
                            });
                          }
                        }}
                        required
                      />
                    </Stack>
                    <Stack>
                      <InputMultiSelect
                        id='other-affiliations'
                        label={t('registration.affiliate')}
                        defaultValues={defaultValues}
                        options={options}
                        onChange={(newValue) => {
                          setInputUser((stateInputUser) => ({
                            ...stateInputUser,
                            institutionsPatient: newValue.map((institution) => ({
                              institutionID: institution.value,
                              institutionName: institution.label,
                            })),
                          }));
                        }}
                      />
                    </Stack>
                  </>
                )
              }
                <Stack numberOfColumns='two-columns' width='medium'>
                  {isPaperlessWithoutAgoraID && (
                  <SelectValidationType
                    id='validationType'
                    label={t('registration.validationtype.label')}
                    value={inputUser.validationType}
                    onChange={(value) => {
                      setInputUser((stateInputUser) => ({ ...stateInputUser, validationType: value }));
                      setError((currentError) => ({ ...currentError, validationType: '' }));
                    }}
                    errorMessage={error.validationType}
                    required
                  />
                  )}
                  {!isPaperlessWithoutAgoraID && (
                  <InputCheckBox
                    className={styles['checkbox-paperless']}
                    id='input-checkbox-registration-paperless'
                    value={t('registration.checkboxpaperless')}
                    checked={enablePaperless}
                    onChange={(checked) => {
                      setEnablePaperless(checked);
                    }}
                  />
                  )}
                </Stack>
                { errorPaperless && <Message /> }
              </>
            </InputContent>
            <ContentButton>
              <Button
                onClick={handleCancel}
                variant='text'
                color='neutral'
              >
                {t('registration.cancel')}
              </Button>
              <Button
                type='submit'
                className='button-valid'
                disabled={loadingPaperless}
              >
                {t('registration.validate')}
              </Button>
            </ContentButton>
          </Form>
        </CardWrapper>
      </div>
      <Popup
        isOpen={popupConfirmation.isOpen}
        type='alert'
        title={popupConfirmation.title}
      >
        <div className={styles['confirmation-popup']}>
          <p>{popupConfirmation.text}</p>
          <ContentButton>
            <Button
              onClick={handleClosePopup}
            >
              {t('registration.close')}
            </Button>
          </ContentButton>
        </div>
      </Popup>
    </>
  );
}

Registration.defaultProps = {
  currentDisabledKeys: [],
  onCancel: undefined,
  onSubmit: undefined,
  onSubmitPaperless: undefined,
  onSubmitFullPaperless: undefined,
  isPaperlessWithoutAgoraID: false,
};

Registration.propTypes = {
  agoraID: PropTypes.string.isRequired,
  institutionID: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    pids: PropTypes.arrayOf(PropTypes.string),
    birthDate: PropTypes.string,
    mobileNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
  userEnroller: PropTypes.shape({
    agoraID: PropTypes.string.isRequired,
    initiale: PropTypes.string.isRequired,
    institutionID: PropTypes.string.isRequired,
  }).isRequired,
  currentDisabledKeys: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitPaperless: PropTypes.func,
  onSubmitFullPaperless: PropTypes.func,
  isPaperlessWithoutAgoraID: PropTypes.bool,
};

export default Registration;
