import axios, { AxiosResponse } from 'axios';

type Branding = {
  name: string,
};

export type Institution = {
  institutionID: string,
  name: string,
  contact_number?: string,
  mailing_address?: string,
  website?: string,
  branding?: {
    [brandingID: string]: Branding,
  }
};

export type UserRole = 'patient' | 'personnel';

export const Validation = ['', 'in_person', 'patient_file'];

export type ValidationType = typeof Validation[number];

type UserStatusInInstitution = {
  institution_affiliated: boolean,
  pre_registered?: boolean,
  roles?: Array<UserRole>,
}

export type ReponseUserStatus = {
  unknown?: boolean
} & UserStatusInInstitution;

type InstitutionPIDsStorage = {
  pids: Array<string>,
  institution: string,
}

export type UserRequest = {
  /* eslint-disable camelcase */
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  mailing_address: string,
  mobile_number: string,
  institutionsPIDs?: Array<InstitutionPIDsStorage>,
  locale: string,
  validation_type: ValidationType,
  /* eslint-enable camelcase */
}

export type PatientRequest = {
  /* eslint-disable camelcase */
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  mailing_address: string,
  mobile_number: string,
  pids?: Array<string>,
  removePids?: Array<string>,
  locale: string,
  validation_type: ValidationType,
  /* eslint-enable camelcase */
}

const baseURL = process.env.REACT_APP_INSTITUTION_PROXY;
const institutionProxyClient = axios.create({ baseURL });

export const retrieveAllInstitutions = async (token: string): Promise<Institution[]> => {
  const url = '/institutions';
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  };

  const response: AxiosResponse<Institution[]> = await institutionProxyClient.get(
    url,
    { headers },
  );

  if (response.status === 204) {
    return [];
  }

  return response.data;
};

export const retrieveUserStatus = async (
  agoraID: string | undefined,
  institutionID: string,
  token: string,
): Promise<ReponseUserStatus> => {
  try {
    if (
      agoraID !== undefined
    ) {
      const url = `/institutions/${institutionID}/users/${agoraID}/status`;
      const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      };

      const response: AxiosResponse<UserStatusInInstitution> = await institutionProxyClient.get(
        url,
        { headers },
      );

      return response.data;
    }

    return {
      institution_affiliated: false,
      unknown: true,
    };
  } catch (err) {
    return {
      institution_affiliated: false,
      unknown: true,
    };
  }
};

export const enrollUser = async (
  user: UserRequest,
  agoraID: string,
  institutionID: string,
  token: string,
): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await institutionProxyClient.put(
    `/institutions/${institutionID}/users/${agoraID}/enroll`,
    user,
    { headers },
  );

  if (response.status !== 204) {
    throw new Error('unknown response from server');
  }
  return true;
};

export const retrieveInstitutionLogo = async (institutionID: string, token: string): Promise<Blob> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response: AxiosResponse<Blob> = await institutionProxyClient.get(
    `/institutions/${institutionID}/logo`,
    {
      headers,
      responseType: 'blob',
    },
  );

  return response.data;
};

export const updatePatient = async (
  user: PatientRequest,
  agoraID: string,
  institutionID: string,
  token: string,
): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await institutionProxyClient.patch(
    `/institutions/${institutionID}/users/${agoraID}/patient`,
    user,
    { headers },
  );

  if (response.status !== 204) {
    throw new Error('unknown response from server');
  }
  return true;
};

export default institutionProxyClient;
