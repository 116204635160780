import LogoutButton from 'components/navigation/logoutButton/LogoutButton';
import styles from './NavList.module.scss';

function NavList() {
  return (
    <div className={styles['bottom-link']}>
      <LogoutButton />
    </div>
  );
}

export default NavList;
