import { ValidationType } from 'api/institutionProxy';
import { getUser, UserStorage } from '../../api/user';

export interface UserRegistered {
  firstName: string,
  lastName: string,
  email: string,
  pids: Array<string>,
  birthDate: string,
  mobileNumber: string,
  street: string,
  city: string,
  country: string,
  institutions: string[],
  locale: string,
  validationType: ValidationType,
}

export function userStorageToUserRegistered(user: UserStorage, institutionID: string): UserRegistered {
  const mailingAddressSplitted: Array<string> = user.mailing_address === undefined
    ? Array(3).fill('')
    : user.mailing_address.split(',');

  const pids = user.institutionsPIDs !== undefined
    ? user.institutionsPIDs
      .filter((institutionPIDs) => institutionPIDs.institution === institutionID)
      .map((filterInstitutionPIDs) => filterInstitutionPIDs.pids)
      .reduce(
        (previousValue, currentValue) => previousValue.concat(currentValue),
        [],
      )
    : [];

  return {
    firstName: user.first_name !== undefined ? user.first_name : '',
    lastName: user.last_name !== undefined ? user.last_name : '',
    email: user.email !== undefined ? user.email : '',
    pids,
    birthDate: user.date_of_birth !== undefined ? user.date_of_birth : '',
    mobileNumber: user.mobile_number !== undefined ? user.mobile_number : '',
    street: mailingAddressSplitted[0].trim(),
    city: mailingAddressSplitted[1].trim(),
    country: mailingAddressSplitted[2].trim(),
    institutions: user.institutions !== undefined ? user.institutions : [],
    locale: user.locale !== undefined ? user.locale : '',
    validationType: '',
  };
}

export async function loadUser(agoraID: string, institutionID: string): Promise<UserRegistered | undefined> {
  if (window.tokenService !== undefined && agoraID !== undefined) {
    const token = await window.tokenService.getToken(agoraID);
    if (token !== undefined) {
      const userloaded: UserStorage = await getUser(agoraID, token);
      return userStorageToUserRegistered(userloaded, institutionID);
    }
  }
  return undefined;
}

export const isTemporaryUser = (temporaryUser: UserRegistered): boolean => (
  temporaryUser.firstName === undefined
    && temporaryUser.lastName === undefined
    && temporaryUser.birthDate === undefined
    && temporaryUser.email === undefined
    && temporaryUser.mobileNumber === undefined
    && temporaryUser.institutions !== undefined
    && Object.keys(temporaryUser.institutions).length === 1
);
