import { atom, selector } from 'recoil';

type Enroller = {
  agoraID: string,
  institutionID: string,
  initiale: string | undefined,
  locale: string,
  hasToChangePassword: boolean,
}

export const enrollerState = atom<Enroller>({
  key: 'Enroller',
  default: undefined,
});

export const selectorEnrollerState = selector({
  key: 'currentEnroller',
  get: ({ get }) => get(enrollerState),
});
