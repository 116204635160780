import PropTypes, { InferProps } from 'prop-types';
import { ReactElement } from 'react';
import navLinkStyles from './NavButton.module.scss';

export type NavigationButtonField = {
  icon?: ReactElement;
  name: string;
  onClick?: () => void;
}

function NavButtonItem(props: InferProps<typeof NavButtonItem.propTypes>) {
  const { navigationField } = props;

  const handleOnClick = () => {
    if (navigationField?.onClick) {
      navigationField.onClick();
    }
  };

  return (
    <button
      type='button'
      className={navLinkStyles.wrapper}
      onClick={handleOnClick}
    >
      <div className={navLinkStyles.icon}>{navigationField?.icon}</div>
      {navigationField?.name}
    </button>
  );
}

NavButtonItem.propTypes = {
  navigationField: PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }).isRequired,
};

export default NavButtonItem;
