import React from 'react';
import PropTypes from 'prop-types';
import './InputCheckBox.css';

interface Props {
  id: string,
  value: string,
  className: string,
  checked: boolean,
  onChange(checked: boolean): void,
}

function InputCheckBox(props: Props): JSX.Element {
  const {
    id, value, onChange, checked, className,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label
      htmlFor={id}
      className={`${className} input-checkbox`}
    >
      <input
        type='checkbox'
        id={id}
        name='disable-input-array'
        onChange={handleChange}
        checked={checked}
      />
      <span>
        { value }
      </span>
    </label>
  );
}

InputCheckBox.defaultProps = {
  className: '',
};

InputCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default InputCheckBox;
