import PropTypes from 'prop-types';
import InputSelect, { Option } from 'components/input/InputSelect';
import { useTranslation } from 'react-i18next';
import { Validation, ValidationType } from 'api/institutionProxy';

type SelectValidationTypeProps = {
  label: string,
  id: string,
  value: string,
  onChange(value: ValidationType): void,
  className: string,
  required: boolean,
  errorMessage: string,
}

function SelectValidationType(props: SelectValidationTypeProps) {
  const {
    label, id, value, onChange, className, required, errorMessage,
  } = props;
  const { t } = useTranslation();

  const options: Option<ValidationType>[] = Validation.filter((validation) => validation !== '').map((validationType) => ({
    value: validationType,
    label: t(`registration.validationtype.type.${validationType}`),
  })) || [];

  return (
    <InputSelect
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      className={className}
      required={required}
      errorMessage={errorMessage}
      options={options}
    />
  );
}

SelectValidationType.defaultProps = {
  onChange: undefined,
  value: '',
  className: '',
  required: false,
  errorMessage: '',
};

SelectValidationType.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SelectValidationType;
