import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import styles from './ContentButton.module.scss';

type Props = {
  className: string,
  children: ReactNode,
}

function ContentButton(props: Props): JSX.Element {
  const { className, children } = props;

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {children}
    </div>
  );
}

ContentButton.defaultProps = {
  className: '',
};

ContentButton.propTypes = {
  className: PropTypes.string,
};

export default ContentButton;
