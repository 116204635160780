import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateToDateFormat } from '../../utils/date';

interface Props {
  value: string,
  className: string,
}

function DateField(props: Props): JSX.Element {
  const {
    value, className,
  } = props;
  const { i18n } = useTranslation();

  const date = new Date(value);
  const options = {
    year: 'numeric', month: 'long', day: 'numeric',
  } as Intl.DateTimeFormatOptions;

  const isValideDate = () => (
    date instanceof Date && !Number.isNaN(date.getTime())
  );

  return (
    <div
      className={className}
    >
      {isValideDate() && dateToDateFormat(date, i18n.language, options)}
    </div>
  );
}

DateField.defaultProps = {
  className: '',
};

DateField.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DateField;
