const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric', month: 'short', day: 'numeric', timeZone,
};

export const dateToDateFormat = (date: Date, locale: string, options?: Intl.DateTimeFormatOptions): string => (
  date.toLocaleDateString(locale, { ...options, ...defaultOptions })
);

export const dateStringToDateFormat = (dateString: string, locale: string): string => {
  const date: Date = new Date(dateString);
  return dateToDateFormat(date, locale);
};

export const getCurrentDate = () : string => {
  const currentDate: Date = new Date();
  return `${currentDate.getFullYear()}-${(`0${currentDate.getMonth() + 1}`).slice(-2)}-${(`0${currentDate.getDate()}`).slice(-2)}`;
};

export const getCurrentDateTime = (): string => {
  const currentDate: Date = new Date();
  const date: string = `${currentDate.getFullYear()}-${(`0${currentDate.getMonth() + 1}`).slice(-2)}-${(`0${currentDate.getDate()}`).slice(-2)}`;
  const time: string = `${`0${currentDate.getHours()}`.slice(-2)}:${`0${currentDate.getMinutes()}`.slice(-2)}:${`0${currentDate.getSeconds()}`.slice(-2)}`;
  return `${date} ${time}`;
};
