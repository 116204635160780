import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InstitutionLogo from 'components/institutionLogo/InstitutionLogo';
import { ReactComponent as XIcon } from 'icons/Agora-Add.svg';
import MobileMenu from 'components/navigation/mobileMenu/MobileMenu';
import { AgoraCareLogo } from '@agora-care/ui-components';
import style from './AgoraTopbar.module.scss';

type CurrentUserInfos = {
  fullName: string,
  agoraID: string,
  institutionID: string,
}

function AgoraTopbar() {
  const [currentUserInfos, setCurrentUserInfos] = useState<CurrentUserInfos>();
  const { t } = useTranslation();

  useEffect(() => {
    const setUserInfos = async () => {
      if (window.authService !== undefined) {
        const user = await window.authService.getUser();
        if (user && user.profile !== undefined) {
          setCurrentUserInfos({
            fullName: user.profile.name || t('content.error'),
            agoraID: user.profile.agora_id as string || t('content.error'),
            institutionID: user.profile.institution as string || '',
          });
        }
      }
    };

    setUserInfos();
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.logos}>
        <AgoraCareLogo submarkOnly className={style['agora-logo']} />
        <XIcon className={style['x-mark']} />
        <InstitutionLogo
          className={style['institution-logo']}
          institutionID={currentUserInfos?.institutionID || ''}
        />
      </div>
      {currentUserInfos && (
        <MobileMenu currentUser={currentUserInfos} />
      )}
    </div>
  );
}

export default AgoraTopbar;
