import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import styles from './Loader.module.scss';

interface Props {
  size: number,
  className: string,
}

function Loader(props: Props): JSX.Element {
  const { size, className } = props;
  const refLoader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refLoader.current) {
      refLoader.current.style.width = `${size}px`;
      refLoader.current.style.height = `${size}px`;
    }
  }, [size]);

  return (
    <div
      ref={refLoader}
      className={`${styles.loader} ${className}`}
    />
  );
}

Loader.defaultProps = {
  size: 50,
  className: '',
};

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

export default Loader;
