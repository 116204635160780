import Select, { MultiValue } from 'react-select';
import PropTypes from 'prop-types';
import styles from './InputMultiSelect.module.scss';

type Props = {
  id: string,
  label: string,
  defaultValues: Array<{ value: string, label: string }>,
  options: Array<{ value: string, label: string }>,
  onChange: (newValue: MultiValue<{ value: string, label: string }>) => void,
};

function InputMultiSelect(props: Props) {
  const {
    id,
    label,
    defaultValues,
    options,
    onChange,
  } = props;

  const handleChange = (newValue: MultiValue<{value: string, label: string}>): void => {
    if (onChange !== undefined) {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Select
        id={id}
        classNames={{
          container: () => styles.container,
          clearIndicator: () => styles['clear-indicator'],
          dropdownIndicator: () => styles['dropdown-indicator'],
          indicatorSeparator: () => styles['indicator-separator'],
          control: () => styles.control,
          menu: () => styles.menu,
          option: () => styles.option,
          valueContainer: () => styles['value-container'],
          multiValue: () => styles['multi-value'],
          multiValueRemove: () => styles['multi-value-remove'],
        }}
        defaultValue={defaultValues}
        options={options}
        unstyled
        onChange={handleChange}
        placeholder=''
        isMulti
      />
      <label htmlFor='affiliations' className={styles.label}>
        {label}
      </label>
    </div>
  );
}

InputMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputMultiSelect;
