import { useState, useEffect } from 'react';

type Config = {
  [key: string]: boolean,
}

// Max widths. Must match ../sass/_breakpoints.scss.
export const XS = 479;
export const SM = 769;
export const MD = 1023;
export const LG = 1149;
export const XL = 1450;

const getBreakpoint = (width: number) => ({
  xs: width <= XS,
  sm: width > XS && width <= SM,
  md: width > SM && width <= MD,
  lg: width > MD && width <= LG,
  xl: width > LG && width <= XL,
  xxl: width > XL,
  up: (breakpoint: string) => {
    const config: Config = {
      xs: true,
      sm: width > XS,
      md: width > SM,
      lg: width > MD,
      xl: width > LG,
      xxl: width > XL,
    };
    return config[breakpoint];
  },
  down: (breakpoint: string) => {
    const config: Config = {
      xs: width <= XS,
      sm: width <= SM,
      md: width <= MD,
      lg: width <= LG,
      xl: width <= XL,
      xxl: true,
    };
    return config[breakpoint];
  },
});

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
    };
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};
export default useBreakpoint;
