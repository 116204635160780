import exchangeToken, { ResponseToken } from '../api/token';

interface Tokens {
  [agoraID: string]: ResponseToken,
}

export default class TokenService {
  tokens: Tokens;

  constructor() {
    this.tokens = {};
  }

  async obtainToken(agoraID: string): Promise<ResponseToken | undefined> {
    const token = await exchangeToken(agoraID);
    if (token !== undefined) {
      this.tokens[agoraID] = token;
    }
    return token;
  }

  async getToken(agoraID: string): Promise<string | undefined> {
    if (this.tokens[agoraID] !== undefined && this.tokens[agoraID].expiration_date > new Date()) {
      return this.tokens[agoraID].access_token;
    }
    const delegateToken = await this.obtainToken(agoraID);
    if (delegateToken !== undefined) {
      return delegateToken.access_token;
    }
    return undefined;
  }
}
