import PropTypes from 'prop-types';
import { ChangeEvent } from 'react';
import styles from './InputRadio.module.scss';

type Props = {
  id: string,
  label: string,
  value: string,
  name: string,
  checked: boolean,
  onChange(value: string): void,
  className: string,
}

function InputRadio(props: Props) {
  const {
    id, label, value, name, onChange, checked, className,
  } = props;

  const hanldeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={className}>
      <input
        className={`${styles.input} ${className}`}
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={hanldeChange}
      />
      <label className={styles.label} htmlFor={id}>{label}</label>
    </div>
  );
}

InputRadio.defaultProps = {
  onChange: undefined,
  className: '',
};

InputRadio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default InputRadio;
