import {
  FormEvent, MouseEvent, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'components/layout/form/Form';
import Stack from 'components/layout/stack/Stack';
import DisplayInfo from 'components/displayInfo/DisplayInfo';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import Message from 'components/message/Message';
import { Button } from '@agora-care/ui-components';
import ContentButton from '../content/ContentButton';
import Title from '../content/Title';
import { UserRegistration } from './UserRegistration';
import InputContent from '../content/InputContent';
import postTransferRequest from '../../api/transferRequest';
import styles from './TransferPatientHistory.module.scss';

interface Props {
  agoraID: string,
  institutionID: string,
  user: UserRegistration,
  onCancel(event: MouseEvent<HTMLButtonElement>): void,
  onSubmit(): void,
}

function TransferPatientHistory(props: Props) {
  const {
    agoraID, institutionID, user, onCancel, onSubmit,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  const sendTransferRequest = async (): Promise<boolean> => {
    try {
      if (window.tokenService !== undefined) {
        const delegateToken = await window.tokenService.getToken(agoraID);

        if (delegateToken !== undefined) {
          await postTransferRequest(agoraID, institutionID, 'full_history', delegateToken);
          return true;
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    const result = await sendTransferRequest();
    if (result) {
      if (onSubmit !== undefined) {
        onSubmit();
      }
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    if (onCancel !== undefined && !loading) {
      onCancel(e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <CardWrapper>
        <Form
          onSubmit={handleSubmit}
        >
          <Title
            title={t('transferpatienthistory.title')}
          />
          <InputContent>
            <>
              <Stack numberOfColumns='two-columns' width='small'>
                <DisplayInfo
                  title={t('user.lastname')}
                  value={user.lastName}
                />
                <DisplayInfo
                  title={t('user.firstname')}
                  value={user.firstName}
                />
              </Stack>
              <Stack>
                <DisplayInfo
                  title={t('user.pid')}
                  value={user.pids}
                />
              </Stack>
            </>
          </InputContent>
          { error && <Message />}
          <ContentButton>
            <>
              <Button
                onClick={handleCancel}
                variant='text'
                color='neutral'
              >
                {t('transferpatienthistory.no')}
              </Button>
              <Button
                type='submit'
                disabled={loading}
              >
                {t('transferpatienthistory.yes')}
              </Button>
            </>
          </ContentButton>
        </Form>
      </CardWrapper>
    </div>
  );
}

TransferPatientHistory.defaultProps = {
  onCancel: undefined,
  onSubmit: undefined,
};

TransferPatientHistory.propTypes = {
  agoraID: PropTypes.string.isRequired,
  institutionID: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    pid: PropTypes.string,
    pids: PropTypes.arrayOf(PropTypes.string),
    birthDate: PropTypes.string,
    mobileNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TransferPatientHistory;
