import { ValidationType } from 'api/institutionProxy';
import axios from 'axios';

const url = process.env.REACT_APP_REGISTRATION_DELEGATE;
const registrationDelegateClient = axios.create({ baseURL: url });

type InstitutionPIDsStorage = {
  pids: Array<string>,
  institution: string,
}

export type UserRegistrationRequest = {
  /* eslint-disable camelcase */
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  mailing_address: string,
  mobile_number: string,
  institutions: Array<string>,
  institutionsPIDs?: Array<InstitutionPIDsStorage>,
  locale: string,
  validation_type: ValidationType,
  /* eslint-enable camelcase */
}

export const register = (
  user: UserRegistrationRequest,
  agoraID: string,
  institutionID: string,
  token: string,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  };

  return registrationDelegateClient.post(
    '/registration',
    {
      ...user,
      agora_id: agoraID,
      institution_id: institutionID,
    },
    { headers },
  );
};

export default registrationDelegateClient;
