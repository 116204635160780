import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetectorApi from './LanguageDetectorApi';

const fallbackLng = 'fr-CH';

const i18n = createInstance({
  ns: ['translation'],
  defaultNS: 'translation',
  fallbackLng,
  returnEmptyString: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  backend: {
    loadPath: () => (
      '/locales/{{lng}}/{{ns}}.json'
    ),
  },
});

i18n
  .use(Backend)
  .use(LanguageDetectorApi)
  .use(initReactI18next)
  .init();

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
