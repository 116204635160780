import { importJWK, GeneralEncrypt, KeyLike } from 'jose';

export const ALG = 'RSA-OAEP-256';

type KeyOps = 'sign' | 'verify' | 'encrypt' | 'decrypt' | 'wrapKey' | 'unwrapeKey' | 'deriveKey' | 'deriveBits';

export type JWK = {
  kty: 'RSA' | 'EC',
  use?: 'sig' | 'enc' | string,
  key_ops?: Array<KeyOps>,
  alg?: string,
  kid: string,
};

export type PublicKeys = Array<JWK>;

type PublicKeyJWK = {
  jwk: KeyLike | Uint8Array,
  jwkReadable: JWK,
}

type JSONValue =
    | string
    | number
    | boolean
    | JSONObject
    | JSONArray;

interface JSONObject {
    [x: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> { }

const usePublicKey = async (publicKey: JWK): Promise<PublicKeyJWK> => {
  const jwk: KeyLike | Uint8Array = await importJWK(publicKey, ALG);
  return {
    jwk,
    jwkReadable: publicKey,
  };
};

const importMultipleJWK = async (publicKeys: PublicKeys) => (
  Promise.all(publicKeys.map((publicKey) => (
    usePublicKey(publicKey)
  )))
);

export const encryptData = async (encryptPublicKeys: PublicKeys, data: JSONObject | JSONArray | JSONValue) => {
  if (!data) {
    throw new Error('Missing data');
  }

  if (encryptPublicKeys.length === 0) {
    throw new Error('Need at least one public key');
  }

  const publicKeys = await importMultipleJWK(encryptPublicKeys);
  const jwe = new GeneralEncrypt(
    new TextEncoder().encode(JSON.stringify(data)),
  ).setProtectedHeader({ enc: 'A256GCM', alg: ALG });

  publicKeys.forEach(async (publicKey) => {
    jwe.addRecipient(publicKey.jwk)
      .setUnprotectedHeader({ kid: publicKey.jwkReadable.kid });
  });

  return jwe.encrypt();
};
