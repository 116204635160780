import { LanguageDetectorAsyncModule } from 'i18next';
import { getUserLanguageWithToken } from '../api/user';

export default {
  type: 'languageDetector',
  async: true,
  init() {},
  detect(callback: (lng: string | readonly string[] | undefined) => void) {
    if (
      window.authService !== undefined
      && window.authService !== null
    ) {
      window.authService.getUser().then((user) => {
        if (
          user !== null
          && user.profile !== undefined
          && user.profile.agora_id !== undefined
        ) {
          return getUserLanguageWithToken(user.profile.agora_id as string, user.access_token).then((lang) => {
            callback(lang);
          });
        }
        throw new Error('unknown user');
      }).catch(() => {
        callback(window.navigator.language);
      });
    } else {
      callback(window.navigator.language);
    }
  },
  cacheUserLanguage() {},
} as LanguageDetectorAsyncModule;
