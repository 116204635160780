import PropTypes from 'prop-types';
import DateField from './DateField';
import InputFields from './InputFields';
import styles from './InputDate.module.scss';

interface Props {
  label: string,
  id: string,
  value: string,
  errorMessage: string,
  onChange(value: string): void,
  required: boolean,
  className: string,
}

function InputDate(props: Props): JSX.Element {
  const {
    errorMessage, id, label, value, onChange, required, className,
  } = props;

  return (
    <div
      className={`${styles.wrapper} ${className}`}
    >
      <InputFields
        label={label}
        id={id}
        value={value}
        type='date'
        errorMessage={errorMessage}
        onChange={onChange}
        required={required}
      />
      <DateField
        value={value}
        className={styles['date-field']}
      />
    </div>
  );
}

InputDate.defaultProps = {
  onChange: undefined,
  value: '',
  errorMessage: '',
  required: false,
  className: '',
};

InputDate.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default InputDate;
