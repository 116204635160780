import PropTypes, { InferProps } from 'prop-types';
import styles from './Stack.module.scss';

function Stack(props: InferProps<typeof Stack.propTypes>) {
  const {
    children,
    numberOfColumns,
    width,
    alignment,
  } = props;

  const classNames = [styles.wrapper];

  if (numberOfColumns) {
    classNames.push(styles[numberOfColumns]);
  }

  if (width) {
    classNames.push(styles[width]);
  }

  if (alignment) {
    classNames.push(styles[alignment]);
  }

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
}

Stack.defaultProps = {
  numberOfColumns: 'auto-columns',
  width: 'large',
  alignment: undefined,
};

Stack.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  numberOfColumns: PropTypes.oneOf([
    'auto-columns',
    'two-columns',
    'three-columns',
    'four-columns',
  ]),
  width: PropTypes.oneOf([
    'large',
    'medium',
    'small',
  ]),
  alignment: PropTypes.oneOf([
    'left',
    'right',
  ]),
};

export default Stack;
