import axios from 'axios';

const baseURL = process.env.REACT_APP_TRANSFER_REQUEST;
const transferRequestClient = axios.create({ baseURL });

export type TypeTransferRequest = 'day' | 'full_history';

export const postTransferRequest = async (agoraID: string, institutionID: string, type: TypeTransferRequest, token: string): Promise<void> => {
  const url = '/transfer-requests';
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/x-www-form-urlencoded',
  };

  const data = new URLSearchParams({
    agora_id: agoraID,
    institution_id: institutionID,
    type,
  });

  await transferRequestClient.post(url, data, { headers });
};

export default postTransferRequest;
