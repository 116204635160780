import axios from 'axios';

const baseURL = process.env.REACT_APP_HEALTHCARE_PROVIDER;
const healthcareProviderClient = axios.create({ baseURL });

export type Pids = string[];
export type RequestType = 'day' | 'full_history';

export interface ErrorActionRequest {
  code: string,
  message: string,
}

export const isErrorActionRequest = (data: unknown): data is ErrorActionRequest => (
  data !== undefined
  && (data as ErrorActionRequest).code !== undefined
  && (data as ErrorActionRequest).message !== undefined
);

export const postActionRequest = async (agoraID: string, token: string, requestType?: RequestType, pids?: Pids): Promise<void> => {
  const url = `/requests/${agoraID}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/x-www-form-urlencoded',
  };

  const data = new URLSearchParams();
  if (requestType !== undefined) {
    data.append('request_type', requestType);
  }

  if (pids !== undefined) {
    pids.forEach((pid) => {
      data.append('pids', pid);
    });
  }

  await healthcareProviderClient.post(url, data, { headers });
};

export default postActionRequest;
