import { UserManagerSettings } from 'oidc-client-ts';

const rootURI: string = process.env.REACT_APP_ROOT_URI !== undefined ? process.env.REACT_APP_ROOT_URI : '';
const authority: string = process.env.REACT_APP_AUTHORITY !== undefined ? process.env.REACT_APP_AUTHORITY : '';
const clientID: string = process.env.REACT_APP_CLIENT_ID !== undefined ? process.env.REACT_APP_CLIENT_ID : '';

export const SCOPE_STAFF = 'staff';

export const IDENTITY_CONFIG: UserManagerSettings = {
  authority,
  client_id: clientID,
  redirect_uri: `${rootURI}/signin-callback.html`,
  silent_redirect_uri: `${rootURI}/silent-renew.html`,
  post_logout_redirect_uri: rootURI,
  response_type: 'code',
  scope: `openid ${SCOPE_STAFF}`,
  automaticSilentRenew: true,
};

export default IDENTITY_CONFIG;
