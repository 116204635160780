import { UserManager } from 'oidc-client-ts';
import PageLoader from 'components/layout/pageLoader/PageLoader';
import IDENTITY_CONFIG from '../../constants/authConst';

function SilentRenew(): JSX.Element {
  new UserManager(
    {
      authority: IDENTITY_CONFIG.authority,
      client_id: IDENTITY_CONFIG.client_id,
      redirect_uri: IDENTITY_CONFIG.redirect_uri,
    },
  ).signinSilentCallback();

  return (
    <PageLoader />
  );
}

export default SilentRenew;
