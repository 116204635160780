import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DoubleArrowIcon } from 'icons/DoubleArrowIcon.svg';
import Message from 'components/message/Message';
import styles from './InputSelect.module.scss';

export type Option<T = string> = {
  value: T,
  label: string,
}

interface Props {
  label: string,
  id: string,
  value: string,
  onChange(value: string): void,
  className: string,
  options: Option[],
  disabled: boolean,
  required: boolean,
  errorMessage: string,
  name?: string,
}

function InputSelect(props: Props): JSX.Element {
  const {
    id, label, value, onChange, className, options, disabled, required, errorMessage, name,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={`${className} ${styles.wrapper} ${errorMessage.length > 0 && styles.error}`}>
      <select
        name={name}
        id={id}
        onChange={handleChange}
        value={value}
        className={styles.input}
        disabled={disabled}
        required={required}
      >
        <option value=''>{' '}</option>
        { options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.value === '|'}
          >
            { option.label }
          </option>
        ))}
      </select>
      <DoubleArrowIcon />
      <label
        htmlFor={id}
        data-testid='label-fields'
        className={styles.label}
      >
        {label}
      </label>
      {
        errorMessage && (
          <Message
            type='error'
            message={errorMessage}
            className={styles.message}
          />
        )
      }
    </div>
  );
}

InputSelect.defaultProps = {
  onChange: undefined,
  value: '',
  className: '',
  disabled: false,
  required: false,
  errorMessage: '',
  name: undefined,
};

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
};

export default InputSelect;
