import axios from 'axios';
import { countryNameToSave } from 'utils/country';
import { ValidationType } from 'api/institutionProxy';

const url = process.env.REACT_APP_USER_PROXY;
const institutionProxyUrl = process.env.REACT_APP_INSTITUTION_PROXY;

const userclient = axios.create({ baseURL: url });
const registrationPaperlessClient = axios.create({ baseURL: institutionProxyUrl });

interface InstitutionPIDsStorage {
  pids: Array<string>,
  institution: string,
}

export interface UserStorage {
  /* eslint-disable camelcase */
  username: string,
  first_name?: string,
  last_name?: string,
  date_of_birth?: string,
  email?: string,
  mailing_address?: string,
  mobile_number?: string,
  institutions?: Array<string>,
  institutionsPIDs?: Array<InstitutionPIDsStorage>,
  locale: string,
  temporary_password: boolean,
  /* eslint-enable camelcase */
}

export interface UserProfile {
  /* eslint-disable camelcase */
  name: string,
  given_name: string,
  family_name: string,
  /* eslint-enable camelcase */
}

export interface Localization {
  locale: string,
}

export interface UserRequest {
  /* eslint-disable camelcase */
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  mailing_address: string,
  mobile_number: string,
  institutions?: Array<string>,
  institutionsPIDs?: Array<InstitutionPIDsStorage>,
  locale: string,
  validation_type: ValidationType,
  /* eslint-enable camelcase */
}

export type UserRegistrationPaperlessRequest = {
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  mailing_address: string,
  mobile_number: string,
  institutions: Array<string>,
  pids?: Array<string>,
  locale: string,
  validation_type: ValidationType,
};

export type UserRegistrationPaperlessResponse = {
  agoraID: string,
};

interface MailingAddress {
  street: string,
  city: string,
  country: string,
  zipCode?: string,
}

export const generateMailingAddress = (mailingAddress: MailingAddress): string => {
  if (mailingAddress.zipCode === undefined) {
    return `${mailingAddress.street.replace(/,/g, '')}, ${mailingAddress.city.replace(/,/g, '')}, ${mailingAddress.country.replace(/,/g, '')}`;
  }
  return `${mailingAddress.street.replace(/,/g, '')}, ${mailingAddress.zipCode.replace(/,/g, '')} ${mailingAddress.city.replace(/,/g, '')}, ${mailingAddress.country.replace(/,/g, '')}`;
};

export const generateMailingAddressISO = (mailingAddress: MailingAddress): string => {
  const country = countryNameToSave(mailingAddress.country);

  return generateMailingAddress({
    ...mailingAddress,
    country,
  });
};

export const getUser = async (agoraID: string, token: string): Promise<UserStorage> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await userclient.get(`${agoraID}/user`, { headers });
  return response.data;
};

export const getProfile = async (agoraID: string, token: string): Promise<UserProfile> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await userclient.get(`${agoraID}/profile`, { headers });
  return response.data;
};

export const getLocale = async (agoraID: string, token: string): Promise<Localization> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await userclient.get(`${agoraID}/localization`, { headers });
  return response.data;
};

export const isPreRegistration = async (agoraID: string, token: string): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await userclient.get(`${agoraID}/pre_registration`, { headers });
  return response.data.pre_registration as boolean;
};

export const updatePIDs = async (pids: string[], institutionID: string, agoraID: string, token: string): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const instituionPIDs = {
    institutionsPIDs: [{
      institution: institutionID,
      pids,
    }],
  };
  const response = await userclient.patch(`${agoraID}/user`, instituionPIDs, { headers });
  if (response.status !== 204) {
    throw new Error('unknown response from server');
  }
  return true;
};

export const getUserLanguageWithToken = async (agoraID: string, token: string): Promise<string> => {
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const response = await axios.get(
    `${url}/${agoraID}/localization`,
    { headers },
  );
  if (
    response.status !== 200
    || response.data.locale === undefined
    || response.data.locale.length === 0
  ) {
    throw new Error('unknown response from server');
  }
  return response.data.locale;
};

export const registerPaperless = async (
  user: UserRegistrationPaperlessRequest,
  institutionID: string,
  token: string,
): Promise<UserRegistrationPaperlessResponse> => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  };

  const response = await registrationPaperlessClient.post(
    `/institutions/${institutionID}/users/enrollment/pingen`,
    {
      ...user,
    },
    { headers },
  );

  return {
    agoraID: response.data.agora_id,
  };
};
