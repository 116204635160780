import axios, { AxiosInstance } from 'axios';
import { Buffer } from 'buffer/';
import { getFormData, QueriesToEncode } from '../utils/httpOperations';

interface RequestToken extends QueriesToEncode {
  /* eslint-disable camelcase */
  grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
  subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
  actor_token_type: 'urn:ietf:params:oauth:token-type:access_token',
  subject_token: string,
  actor_token: string,
  /* eslint-enable camelcase */
}

export interface ResponseToken {
  /* eslint-disable camelcase */
  access_token: string,
  expires_in: string,
  issued_token_type: string,
  token_type: string,
  expiration_date: Date,
  /* eslint-enable camelcase */
}

const url: string | undefined = process.env.REACT_APP_TOKEN_VALIDATOR;
const tokenclient: AxiosInstance = axios.create({ baseURL: url });

async function exchangeToken(agoraID: string): Promise<ResponseToken | undefined> {
  if (window.authService !== undefined) {
    const textEncoder: TextEncoder = new TextEncoder();
    const subjectToken: string = Buffer.from(textEncoder.encode(`{"sub":"${agoraID}"}`)).toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
    const data: RequestToken = {
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      subject_token: `eyJhbGciOiJub25lIn0.${subjectToken}.`,
      subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
      actor_token: window.authService.getAccessToken(),
      actor_token_type: 'urn:ietf:params:oauth:token-type:access_token',
      scope: 'institution_delegate',
    };

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const queries = getFormData(data);
    const response = await tokenclient.post('token', queries, { headers });
    const expirationDate: Date = new Date();
    const securityTime: number = 30;
    expirationDate.setSeconds(expirationDate.getSeconds() + response.data.expires_in - securityTime);
    return { ...response.data, expiration_date: expirationDate };
  }
  return undefined;
}

export default exchangeToken;
