import PropTypes, { InferProps } from 'prop-types';
import styles from './CardWrapper.module.scss';

function CardWrapper(props: InferProps<typeof CardWrapper.propTypes>) {
  const { children } = props;

  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
}

CardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CardWrapper;
