import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LayoutFormAgora from 'components/agora/LayoutFormAgora';
import LayoutViewUser from 'components/user/LayoutViewUser';
import { getUser } from 'api/user';
import LayoutUser from '../user/LayoutUser';
import LayoutRegistration from '../registration/LayoutRegistration';
import LayoutEditUser from '../user/LayoutEditUser';
import MainContent from '../content/MainContent';
import LayoutUserAffiliation from '../useraffiliation/LayoutUserAffiliation';
import { privateKeyState, publicJWKState } from '../../state/key';
import { retrieveAllInstitutions } from '../../api/institutionProxy';
import { institutionsState } from '../../state/institutions';
import { enrollerState } from '../../state/enroller';
import OidcSignin from '../oidc/OidcSignin';

function App(): JSX.Element {
  const [agoraID, setAgoraID] = useState<string>('');
  const [institutionID, setInstitutionID] = useState<string>('');
  const [statePublicJWK, setStatePublicJWK] = useRecoilState(publicJWKState);
  const [statePrivateKey, setStatePrivateKey] = useRecoilState(privateKeyState);
  const setStateEnroller = useSetRecoilState(enrollerState);
  const setStateInstitutions = useSetRecoilState(institutionsState);

  useEffect(() => {
    const createKeyPair = async () => {
      const { subtle } = window.crypto;
      // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/generateKey#rsa_key_pair_generation
      const { publicKey, privateKey } = await subtle.generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 4096,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256',
        },
        true,
        ['encrypt', 'decrypt'],
      );

      const publicJWK = await subtle.exportKey('jwk', publicKey);

      setStatePublicJWK(publicJWK);
      setStatePrivateKey(privateKey);
    };
    if (
      agoraID.length > 0
      && (
        statePublicJWK === undefined
        || statePrivateKey === undefined
      )
    ) {
      createKeyPair();
    }
  }, [agoraID]);

  useEffect(() => {
    const setInstitutionsList = async (token: string) => {
      const institutions = await retrieveAllInstitutions(token);
      setStateInstitutions(institutions);
    };

    if (
      agoraID.length > 0
      && window.authService !== undefined
    ) {
      const token = window.authService.getAccessToken();
      setInstitutionsList(token);
    }
  }, [agoraID]);

  const getEnrollerPasswordStatus = async (agoraId: string) => {
    try {
      if (window.authService !== undefined) {
        const token = window.authService.getAccessToken();
        if (token !== undefined) {
          const user = await getUser(agoraId, token);

          return user.temporary_password;
        }
      }

      return false;
    } catch {
      return false;
    }
  };

  return (
    <OidcSignin
      onProfileLoaded={async (profile) => {
        setAgoraID(profile.agoraID);
        setInstitutionID(profile.institutionID);
        setStateEnroller({
          agoraID: profile.agoraID,
          institutionID: profile.institutionID,
          initiale: profile.name && profile.name.split(' ').map((v) => v[0]).join(''),
          locale: profile.locale,
          hasToChangePassword: await getEnrollerPasswordStatus(profile.agoraID),
        });
      }}
    >
      <Router>
        {(agoraID.length > 0 && institutionID.length > 0) && (
          <MainContent>
            <Routes>
              <Route
                path='/:agoraID/edit'
                element={(
                  <LayoutEditUser
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/user'
                element={(
                  <LayoutViewUser
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/enrollment'
                element={(
                  <LayoutRegistration
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/affiliation'
                element={(
                  <LayoutUserAffiliation />
                  )}
              />
              <Route
                path='/:agoraID'
                element={(
                  <LayoutUser />
                  )}
              />
              <Route
                path='/enrollment'
                element={(
                  <LayoutRegistration
                    institutionID={institutionID}
                    isPaperlessWithoutAgoraID
                  />
                  )}
              />
              <Route
                path='/'
                element={(
                  <LayoutFormAgora />
                  )}
              />
            </Routes>
          </MainContent>
        )}
      </Router>
    </OidcSignin>
  );
}

export default App;
