import axios from 'axios';
import { compactDecrypt } from 'jose';
import { encryptData, PublicKeys } from '../services/Encrypt';

const baseURL = process.env.REACT_APP_PATIENT_DEMOGRAPHIC_QUERY;
const patientDemographicQueryClient = axios.create({ baseURL });

export type UserInformation = {
  patient_name?: {
    family_name?: string,
    given_name?: string,
  },
  date_of_birth?: string,
  patient_address?: {
    street_address?: {
      street_or_mailing_address?: string,
    },
    city?: string,
    postal_code?: string,
    country?: string,
  },
  telephone_number?: string,
  email_address?: string,
  pid: string,
};

export const getPublicKeys = async (token: string): Promise<PublicKeys> => {
  const url = '/public-keys';
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/jwk-set+json',
  };

  const result = await patientDemographicQueryClient.get(url, { headers });

  if (result.status === 204) {
    return [];
  }

  return result.data.keys;
};

export const postEncryptedPID = async (
  remotePublicKeys: PublicKeys,
  publicKey: JsonWebKey,
  privateKey: CryptoKey,
  pid: string,
  token: string,
): Promise<UserInformation | null> => {
  const requestContents = {
    request_type: 'patient_demographics_query',
    patient_identifiers: [
      {
        id_number: pid,
      },
    ],
  };

  const jwe = await encryptData(remotePublicKeys, requestContents);

  const url = '/request';
  const headers = {
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  };

  const result = await patientDemographicQueryClient.post(
    url,
    {
      encrypted_query: jwe,
      public_key: {
        e: publicKey.e,
        n: publicKey.n,
        kty: publicKey.kty,
      },
    },
    { headers, timeout: 15000 },
  );

  if (
    result.status === 204
    || result.data === undefined
    || result.data.result === undefined
  ) {
    throw new Error('No data returned');
  }

  const { plaintext } = await compactDecrypt(result.data.result, privateKey);
  const dataDecoded = new TextDecoder().decode(plaintext);

  return {
    ...JSON.parse(dataDecoded),
    pid,
  };
};
