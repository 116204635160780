import { createInstance } from 'i18next';
import Backend from 'i18next-http-backend';
import { jsPDF } from 'jspdf';
import printJS from 'print-js';
import QRCode from 'qrcode';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js';
import { countryName } from 'utils/country';
import { dateStringToDateFormat, getCurrentDateTime } from '../utils/date';

export interface UserEnroller {
  agoraID: string,
  initiale: string,
  institutionID: string,
}

interface PDFValues {
  firstName: string,
  lastName: string,
  birthDate: string,
  email: string,
  mobileNumber: string,
  street: string,
  city: string,
  zipCode: string,
  country: string,
  agoraID: string,
  userEnroller: UserEnroller,
  institutions?: string[],
}

export const availableLocales = [
  {
    key: 'french',
    nativeName: 'Français',
    locale: 'fr-CH',
  },
  {
    key: 'english',
    nativeName: 'Anglais',
    locale: 'en-GB',
  },
  {
    key: 'italian',
    nativeName: 'Italien',
    locale: 'it-CH',
  },
  {
    key: 'german',
    nativeName: 'Allemand',
    locale: 'de-CH',
  },
];

export const fallbackLng = 'fr-CH';

const i18n = createInstance({
  fallbackLng,
  lng: 'fr-CH',
  ns: 'enrollment-form',
  defaultNS: 'enrollment-form',
  returnEmptyString: false,
  backend: {
    loadPath: () => (
      '/locales/{{lng}}/{{ns}}.json'
    ),
  },
});

i18n.use(Backend).init();

export default class EnrollmentForm {
  locale: string;

  agoraLogo: HTMLImageElement;

  constructor(locale: string) {
    i18n.changeLanguage(locale);
    this.locale = locale;
    this.agoraLogo = new Image();
    this.agoraLogo.src = '/Logo.jpeg';
  }

  static docDrawCapsLine(doc: jsPDF, x: number, y: number, maxWidth: number) {
    const letterWidth = 11;
    const letterHeight = 10;
    const numberOfLetters = ((maxWidth - x) / letterWidth);

    doc.line(x, y, maxWidth, y);
    for (let i = 0; i < numberOfLetters - 1; i += 1) {
      const xLetter = x + (letterWidth * i);
      doc.line(xLetter, y, xLetter, y - letterHeight);
    }
    doc.line(maxWidth, y, maxWidth, y - letterHeight);
  }

  static docWriteField(doc: jsPDF, value: string, x: number, y: number, maxWidth: number): void {
    if (
      value.trim().length > 0
    ) {
      doc.text(value, x, y);
    } else {
      EnrollmentForm.docDrawCapsLine(doc, x, y, maxWidth);
    }
  }

  async createPDF(values: PDFValues): Promise<void> {
    // eslint-disable-next-line new-cap
    const doc: jsPDF = new jsPDF({ orientation: 'p', unit: 'px', format: 'a4' });
    const width: number = doc.internal.pageSize.getWidth();
    const height: number = doc.internal.pageSize.getHeight();

    const marginLeftRight = 47;
    const marginTop = 20;
    const marginBottom = 80;
    const imageHeight = 38;
    const marginList = marginLeftRight + 5;
    const marginListElem = marginList + 110;
    const marginTopTitle = marginTop + imageHeight + 40;
    const marginTopText = marginTopTitle + 18;
    const marginBottomText = 15;
    const marginLeft = width - marginLeftRight;
    const birthDate = values.birthDate.length > 0 ? dateStringToDateFormat(values.birthDate, i18n.language) : '';
    const qrCodeImageSize = 38;

    doc.addImage(this.agoraLogo, 'JPEG', marginLeftRight, marginTop, (imageHeight * 1000) / 220, imageHeight);

    doc.setFontSize(18);
    doc.text(i18n.t('title'), marginLeftRight, marginTopTitle);

    doc.setFontSize(12);
    doc.text(
      i18n.t('introduction'),
      marginLeftRight,
      marginTopText,
      { maxWidth: width - (marginLeftRight * 2) },
    );

    const startForm = 5;
    const addEmailLine = values.email.trim().length > 0 ? 0 : 1;
    doc.text(i18n.t('listfirstname'), marginList, marginTopText + marginBottomText * startForm);
    EnrollmentForm.docWriteField(doc, values.firstName, marginListElem, marginTopText + marginBottomText * startForm, marginLeft);
    doc.text(i18n.t('listlastname'), marginList, marginTopText + marginBottomText * (startForm + 1));
    EnrollmentForm.docWriteField(doc, values.lastName, marginListElem, marginTopText + marginBottomText * (startForm + 1), marginLeft);
    doc.text(i18n.t('listdateofbirth'), marginList, marginTopText + marginBottomText * (startForm + 2));
    EnrollmentForm.docWriteField(doc, birthDate, marginListElem, marginTopText + marginBottomText * (startForm + 2), marginLeft);
    doc.text(i18n.t('listemail'), marginList, marginTopText + marginBottomText * (startForm + 3));
    EnrollmentForm.docWriteField(doc, values.email, marginListElem, marginTopText + marginBottomText * (startForm + 3), marginLeft);
    if (values.email.trim().length === 0) {
      EnrollmentForm.docWriteField(doc, values.email, marginListElem, marginTopText + marginBottomText * (startForm + 3 + addEmailLine), marginLeft);
    }
    doc.text(i18n.t('listphonenumber'), marginList, marginTopText + marginBottomText * (startForm + 4 + addEmailLine));
    EnrollmentForm.docWriteField(doc, formatPhoneNumberIntl(values.mobileNumber as E164Number), marginListElem, marginTopText + marginBottomText * (startForm + 4 + addEmailLine), marginLeft);
    doc.text(i18n.t('listmailingaddress'), marginList, marginTopText + marginBottomText * (startForm + 5 + addEmailLine));
    EnrollmentForm.docWriteField(doc, values.street, marginListElem, marginTopText + marginBottomText * (startForm + 5 + addEmailLine), marginLeft);
    EnrollmentForm.docWriteField(doc, `${values.zipCode} ${values.city}`, marginListElem, marginTopText + marginBottomText * (startForm + 6 + addEmailLine), marginLeft);
    EnrollmentForm.docWriteField(doc, countryName(values.country, this.locale), marginListElem, marginTopText + marginBottomText * (startForm + 7 + addEmailLine), marginLeft);

    const start = 14;
    doc.text(i18n.t('validation'), marginLeftRight, marginTopText + marginBottomText * start);
    doc.text(i18n.t('listdataisexact'), marginList, marginTopText + marginBottomText * (start + 1));
    doc.text(i18n.t('listoktocreateaccount'), marginList, marginTopText + marginBottomText * (start + 2));
    doc.text(i18n.t('listdocumentation'), marginList, marginTopText + marginBottomText * (start + 3));
    doc.text(i18n.t('listsendimages'), marginList, marginTopText + marginBottomText * (start + 4), { maxWidth: width - (marginLeftRight * 2) });
    doc.text(i18n.t('listinstitutions'), marginList, marginTopText + marginBottomText * (start + 5.7), { maxWidth: width - (marginLeftRight * 2) });
    values.institutions?.forEach((institution, index) => {
      doc.text(institution, marginList + 10, marginTopText + marginBottomText * (start + 7.2 + index), { maxWidth: width - (marginLeftRight * 2) });
    });
    doc.text(i18n.t('date'), marginLeftRight, marginTopText + marginBottomText * (start + 11));
    doc.text(i18n.t('signature'), marginLeftRight, marginTopText + marginBottomText * (start + 13));

    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(8);
    doc.line(marginLeftRight, height - marginBottom, width - marginLeftRight, height - marginBottom);
    doc.text('Agora Care SA\nc/o FONGIT, Route de la Galaise 34\n1228 Plan-les-Ouates\nSuisse', marginLeftRight, height - marginBottom + 20);
    doc.text('www.agoracare.ch\ncontact@agoracare.ch', width - marginLeftRight - qrCodeImageSize, height - marginBottom + 20 + 10 * 2, { align: 'right' });
    doc.text(`${values.userEnroller.initiale}, ${values.userEnroller.agoraID}, ${values.userEnroller.institutionID}, ${getCurrentDateTime()}`, marginLeftRight, height - 10);

    doc.setFontSize(8);
    doc.text(`AC-${values.agoraID}`, width - marginLeftRight, height - 10, { align: 'right' });

    const dataURL = await QRCode.toDataURL(values.agoraID);
    doc.addImage(dataURL, 'png', width - marginLeftRight - 32, height - marginBottom + 15, qrCodeImageSize, qrCodeImageSize);

    const isSafari: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (isSafari || isFirefox) {
      const w = window.open(doc.output('bloburl'), 'registration');
      if (w !== null) {
        w.print();
      }
    } else {
      printJS({ printable: doc.output('bloburl'), type: 'pdf' });
    }
  }
}
