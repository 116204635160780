import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import Form from 'components/layout/form/Form';
import Stack from 'components/layout/stack/Stack';
import Message from 'components/message/Message';
import SelectValidationType from 'components/input/SelectValidationType';
import { Button } from '@agora-care/ui-components';
import InputFields from '../input/InputFields';
import PhoneInputFields from '../input/PhoneInputFields';
import { generateMailingAddress } from '../../api/user';
import { PatientRequest, ValidationType, updatePatient } from '../../api/institutionProxy';
import { validateEmail, validatePhoneNumber } from '../../utils/valid';
import { UserRegistered } from './UserRegistred';
import InputArray from '../input/InputArray';
import ContentButton from '../content/ContentButton';
import Title from '../content/Title';
import InputContent from '../content/InputContent';
import InputDate from '../input/InputDate';
import SelectLang from '../input/SelectLang';
import InputEmail from '../input/InputEmail';
import { dateToDateFormat } from '../../utils/date';
import { minDate } from '../../constants/birthdateConst';
import styles from './UpdateUser.module.scss';

interface Props {
  agoraID: string,
  institutionID: string,
  user: UserRegistered,
  onUpdate(userUpdated: UserRegistered): void,
  onCancel(): void,
  onChange(change: boolean): void,
}

interface InputUserError {
  firstName: string,
  lastName: string,
  birthDate: string,
  pids: string,
  email: string,
  mobileNumber: string,
  street: string,
  city: string,
  country: string,
  validationType: string,
}

interface StateInputUser extends UserRegistered {
  removePids: string[],
  validationType: ValidationType,
}

function UpdateUser(props: Props): JSX.Element {
  const {
    agoraID, institutionID, user, onUpdate, onCancel, onChange,
  } = props;
  const [inputUser, setInputUser] = useState<StateInputUser>({
    ...user,
    removePids: [],
    validationType: '',
  });
  const [error, setError] = useState<InputUserError>({
    firstName: '',
    lastName: '',
    birthDate: '',
    pids: '',
    email: '',
    mobileNumber: '',
    street: '',
    city: '',
    country: '',
    validationType: '',
  });
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledPIDs, setDisabledPIDs] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const requiredMessage = t('user.requiredfield');

  const checkErrorLenght = (key: keyof UserRegistered, value: string | string[]): boolean => {
    if (value.length === 0) {
      setError((currentError) => ({ ...currentError, [key]: requiredMessage }));
      return true;
    }
    setError((currentError) => ({ ...currentError, [key]: '' }));
    return false;
  };

  const checkErrorEmail = (email: string): boolean => {
    if (email.length > 0 && !validateEmail(email)) {
      setError((currentError) => ({ ...currentError, email: t('user.invalidemail') }));
      return true;
    }
    return checkErrorLenght('email', email);
  };

  const checkErrorMobileNumber = (mobileNumber: string): boolean => {
    if (!validatePhoneNumber(mobileNumber)) {
      setError((currentError) => ({ ...currentError, mobileNumber: t('user.invalidmobilenumber') }));
      return true;
    }
    return checkErrorLenght('mobileNumber', mobileNumber);
  };

  const checkErrorBirthdate = (birthDate: string) => {
    const date = new Date(birthDate);

    if (date < minDate) {
      const minDateReadable = dateToDateFormat(minDate, i18n.language);
      setError((currentError) => ({ ...currentError, birthDate: t('user.invalidbirthdatetoosmall', { birthdate: minDateReadable }) }));
      return true;
    }

    if (date > new Date()) {
      setError((currentError) => ({ ...currentError, birthDate: t('user.invalidbirthdatetoobig') }));
      return true;
    }
    return false;
  };

  const checkFields = async () => {
    const errorOccured = (await Promise.all([
      checkErrorLenght('firstName', inputUser.firstName),
      checkErrorLenght('lastName', inputUser.lastName),
      !disabledPIDs && checkErrorLenght('pids', inputUser.pids),
      checkErrorLenght('email', inputUser.email),
      checkErrorLenght('birthDate', inputUser.birthDate),
      checkErrorLenght('mobileNumber', inputUser.mobileNumber),
      checkErrorLenght('street', inputUser.street),
      checkErrorLenght('city', inputUser.city),
      checkErrorLenght('country', inputUser.country),
      checkErrorEmail(inputUser.email),
      checkErrorMobileNumber(inputUser.mobileNumber),
      checkErrorBirthdate(inputUser.birthDate),
      checkErrorLenght('validationType', inputUser.validationType),
    ])).reduce((previousValue, currentValue) => (
      previousValue || currentValue
    ), false);

    return !errorOccured;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      setUpdateError(false);
      if (await checkFields() && window.tokenService !== undefined && agoraID !== undefined && institutionID !== undefined) {
        const token = await window.tokenService.getToken(agoraID);
        if (token !== undefined) {
          const mailingAddress = generateMailingAddress(inputUser);
          let newUser: PatientRequest = {
            email: inputUser.email.trim(),
            first_name: inputUser.firstName.trim(),
            last_name: inputUser.lastName.trim(),
            mobile_number: inputUser.mobileNumber,
            date_of_birth: inputUser.birthDate,
            mailing_address: mailingAddress.trim(),
            locale: inputUser.locale,
            validation_type: inputUser.validationType,
          };

          if (!disabledPIDs) {
            newUser = {
              ...newUser,
              pids: inputUser.pids,
            };
          }

          if (inputUser.removePids.length > 0) {
            newUser = {
              ...newUser,
              removePids: inputUser.removePids,
            };
          }

          await updatePatient(newUser, agoraID, institutionID, token);
        }
        setLoading(false);
        if (onUpdate !== undefined) {
          onUpdate(inputUser);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setUpdateError(true);
      setLoading(false);
    }
  };

  const handleChange = (key: keyof UserRegistered, value: string | string[]) => {
    setInputUser((stateInputUser) => ({ ...stateInputUser, [key]: value }));
    checkErrorLenght(key, value);
  };

  const handleChangePIDs = (pids: string[]) => {
    const removePids = user.pids.filter((pid) => !pids.includes(pid));
    setInputUser((stateInputUser) => ({ ...stateInputUser, pids, removePids }));
    checkErrorLenght('pids', pids);
  };

  const handleDisabledPIDs = (disabled: boolean) => {
    setDisabledPIDs(disabled);
    setInputUser((stateInputUser) => ({ ...stateInputUser, removePids: user.pids }));
  };

  const handleChangeEmail = (email: string) => {
    setInputUser((stateInputUser) => ({ ...stateInputUser, email }));
    checkErrorEmail(email);
  };

  const handleChangeMobileNumber = (mobileNumber: string) => {
    setInputUser((stateInputUser) => ({ ...stateInputUser, mobileNumber }));
    checkErrorMobileNumber(mobileNumber);
  };

  const handleCancel = () => {
    if (onCancel !== undefined) {
      onCancel();
    }
  };

  useEffect(() => {
    if (onChange !== undefined) {
      onChange(JSON.stringify(inputUser) !== JSON.stringify(user));
    }
  }, [inputUser, user]);

  return (
    <div className={styles.wrapper}>
      <CardWrapper>
        <Form onSubmit={handleSubmit}>
          <Title title={t('user.updateuser')} />
          <InputContent>
            <>
              <div>{t('user.agoracareid', { agora_care_id: agoraID })}</div>
              <Stack numberOfColumns='two-columns' width='medium'>
                <InputFields
                  label={t('user.firstname')}
                  id='first_name'
                  value={inputUser.firstName}
                  errorMessage={error.firstName}
                  onChange={(value) => handleChange('firstName', value)}
                  required
                />
                <InputFields
                  label={t('user.lastname')}
                  id='last_name'
                  value={inputUser.lastName}
                  errorMessage={error.lastName}
                  onChange={(value) => handleChange('lastName', value)}
                  required
                />
              </Stack>
              <Stack numberOfColumns='two-columns' width='medium'>
                <InputDate
                  label={t('user.dateofbirth')}
                  id='date_of_birth'
                  value={inputUser.birthDate}
                  onChange={(birthDate) => handleChange('birthDate', birthDate)}
                  errorMessage={error.birthDate}
                  required
                />
              </Stack>
              <Stack numberOfColumns='two-columns' width='medium'>
                <InputEmail
                  label={t('user.email')}
                  id='email'
                  value={inputUser.email}
                  errorMessage={error.email}
                  onChange={(value) => handleChangeEmail(value)}
                  required
                />
                <PhoneInputFields
                  label={t('user.mobilenumber')}
                  id='mobile_number'
                  value={inputUser.mobileNumber}
                  errorMessage={error.mobileNumber}
                  onChange={(mobileNumber) => handleChangeMobileNumber(mobileNumber)}
                  required
                />
              </Stack>
              <Stack>
                <InputFields
                  label={t('user.street')}
                  id='street'
                  value={inputUser.street}
                  errorMessage={error.street}
                  onChange={(value) => handleChange('street', value)}
                  required
                />
              </Stack>
              <Stack numberOfColumns='three-columns' width='medium'>
                <InputFields
                  label={t('user.city')}
                  id='city'
                  value={inputUser.city}
                  errorMessage={error.city}
                  onChange={(value) => handleChange('city', value)}
                  required
                />
                <InputFields
                  label={t('user.country')}
                  id='country'
                  value={inputUser.country}
                  errorMessage={error.country}
                  onChange={(value) => handleChange('country', value)}
                  required
                />
              </Stack>
              <Stack numberOfColumns='two-columns' width='medium'>
                <SelectLang
                  label={t('user.preferedlang')}
                  id='select-user-lang'
                  value={inputUser.locale}
                  onChange={(value) => {
                    setInputUser((stateInputUser) => ({ ...stateInputUser, locale: value }));
                  }}
                />
              </Stack>
              <Stack>
                <InputArray
                  label={t('user.pid')}
                  id='pid'
                  errorMessage={error.pids}
                  values={inputUser.pids}
                  onUpdate={handleChangePIDs}
                  deactivatable
                  deactivatableText={t('updateuser.deactivatablePIDs')}
                  disabled={disabledPIDs}
                  onDisabled={handleDisabledPIDs}
                  required
                />
              </Stack>
              <Stack numberOfColumns='two-columns' width='medium'>
                <SelectValidationType
                  id='validationType'
                  label={t('registration.validationtype.label')}
                  value={inputUser.validationType}
                  onChange={(value) => {
                    setInputUser((stateInputUser) => ({ ...stateInputUser, validationType: value }));
                    setError((currentError) => ({ ...currentError, validationType: '' }));
                  }}
                  errorMessage={error.validationType}
                  required
                />
              </Stack>
              { updateError && <Message /> }
            </>
          </InputContent>
          <ContentButton>
            <Button
              variant='text'
              color='neutral'
              onClick={handleCancel}
              disabled={loading}
            >
              {t('user.cancel')}
            </Button>
            <Button
              type='submit'
              disabled={loading}
            >
              {t('user.update')}
            </Button>
          </ContentButton>
        </Form>
      </CardWrapper>
    </div>
  );
}

UpdateUser.defaultProps = {
  onCancel: undefined,
  onUpdate: undefined,
  onChange: undefined,
};

UpdateUser.propTypes = {
  agoraID: PropTypes.string.isRequired,
  institutionID: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    pids: PropTypes.arrayOf(PropTypes.string),
    birthDate: PropTypes.string,
    mobileNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};

export default UpdateUser;
