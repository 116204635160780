import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import { ValidationEmail } from '../../api/emailValidator';

interface Props {
  verdict: ValidationEmail,
}

function InputEmailVerdict(props: Props): JSX.Element {
  const {
    verdict,
  } = props;

  const { t } = useTranslation();

  const messages = (() => {
    const message: string[] = [];

    if (verdict.verdict === 'Valid') {
      message.push(t('inputemail.verdictvalid'));
    }

    if (verdict.verdict !== 'Valid') {
      message.push(t('inputemail.verdictinvalid'));
    }

    if (verdict.suggestions !== undefined && verdict.suggestions.length > 0) {
      message.push(t('inputemail.suggestions', { suggestions: verdict.suggestions.join(', '), count: verdict.suggestions.length }));
    }

    return message;
  })();

  return (
    verdict.verdict !== 'Valid'
  ) ? (
    <Message
      type='warning'
      message={messages}
    />
    ) : (
      <Message
        type='success'
        message={messages}
      />
    );
}

InputEmailVerdict.propTypes = {
  verdict: PropTypes.shape({
    verdict: PropTypes.string.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default InputEmailVerdict;
