import { useEffect, useState } from 'react';
import InstitutionLogo from 'components/institutionLogo/InstitutionLogo';
import { useTranslation } from 'react-i18next';
import styles from './UserBadge.module.scss';

type CurrentUserInfos = {
  fullName: string,
  agoraID: string,
  institutionID: string,
}

function UserBadge() {
  const [currentUserInfos, setCurrentUserInfos] = useState<CurrentUserInfos>();
  const { t } = useTranslation();

  useEffect(() => {
    const setUserInfos = async () => {
      if (window.authService !== undefined) {
        const user = await window.authService.getUser();
        if (user && user.profile !== undefined) {
          setCurrentUserInfos({
            fullName: user.profile.name || t('content.error'),
            agoraID: user.profile.agora_id as string || t('content.error'),
            institutionID: user.profile.institution as string || '',
          });
        }
      }
    };

    setUserInfos();
  }, []);

  return (
    <div
      className={styles['user-badge']}
    >
      <div
        className={styles['user-badge-content']}
      >
        <div
          className={styles['user-badge-hole']}
        />
      </div>
      <div
        className={styles['user-badge-content']}
      >
        <div className={styles['institution-logo']}>
          <InstitutionLogo institutionID={currentUserInfos?.institutionID || ''} />
        </div>
      </div>
      <div
        className={styles['user-badge-content']}
      >
        <span
          className={styles['user-badge-secondary']}
        >
          {t('userBadge.staff')}
        </span>
        <span>{currentUserInfos?.fullName}</span>
      </div>
      <div
        className={styles['user-badge-content']}
      >
        <span
          className={styles['user-badge-secondary']}
        >
          Agora Care ID
        </span>
        <span>{currentUserInfos?.agoraID}</span>
      </div>
    </div>
  );
}

export default UserBadge;
