import PropTypes from 'prop-types';
import './Title.css';

type Props = {
  title: string,
  className: string,
  children?: JSX.Element,
}

function Title(props: Props): JSX.Element {
  const { title, className, children } = props;

  return (
    <div
      className={`${className} content-title`}
    >
      <div className='title'>
        {title}
      </div>
      {children}
    </div>
  );
}

Title.defaultProps = {
  className: '',
  children: null,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Title;
