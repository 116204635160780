import { useEffect, useRef } from 'react';
import Popup from 'components/popup/Popup';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import i18n from 'services/i18n';
import { enrollerState } from 'state/enroller';
import Form from 'components/layout/form/Form';
import styles from './AccountCredentialsUpdate.module.scss';

function AccountCredentialsUpdate() {
  const [enroller, setEnroller] = useRecoilState(enrollerState);
  const { t } = useTranslation();

  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const targetOrigin = process.env.REACT_APP_IDP_URI ?? '';

  async function postToken() {
    if (
      iFrameRef.current !== null && window.authService !== undefined) {
      const user = await window.authService.getUser();
      const currentDate = new Date();
      const expirationDate = new Date(0);
      if (user?.expires_at !== undefined) {
        expirationDate.setUTCSeconds(user.expires_at);
        const ttl = (expirationDate.getTime() - currentDate.getTime());
        iFrameRef.current.contentWindow?.postMessage(
          `token:${user?.access_token} ttl:${ttl} agoraID:${enroller.agoraID}`,
          targetOrigin,
        );
      }
    }
  }

  useEffect(() => {
    function manageMessage(e: MessageEvent) {
      if (e.origin === targetOrigin) {
        if (e.data === 'update') {
          setEnroller((prev) => ({
            ...prev,
            hasToChangePassword: false,
          }));
        }

        if (e.data === 'cancel') {
          setEnroller((prev) => ({
            ...prev,
            hasToChangePassword: false,
          }));
        }
      }
    }

    window.addEventListener('message', manageMessage);
    return () => {
      window.removeEventListener('message', manageMessage);
    };
  }, [t, targetOrigin]);

  return (
    <Popup
      title={t('updatePassword.title')}
      isOpen={enroller !== undefined && enroller.hasToChangePassword}
    >
      <Form>
        <div
          className={styles['iframe-wrapper']}
        >
          <iframe
            src={`${targetOrigin}/management/iframe-password/${i18n.language}/index.html`}
            title='update password'
            className={styles['container-password-iframe']}
            ref={iFrameRef}
            onLoad={postToken}
          />
        </div>
      </Form>
    </Popup>
  );
}

export default AccountCredentialsUpdate;
