import {
  useState, useRef, useEffect, ChangeEvent, KeyboardEvent, FocusEvent, MouseEvent, ClipboardEvent,
} from 'react';
import PropTypes from 'prop-types';
import styles from './InputAgora.module.scss';

interface Props {
  label: string,
  onChange(agoraID: string): void,
}

function AgoraIdField(props: Props): JSX.Element {
  const {
    label, onChange,
  } = props;

  const refHeader = useRef<HTMLInputElement>(null);
  const refFirst = useRef<HTMLInputElement>(null);
  const refSecond = useRef<HTMLInputElement>(null);
  const refThird = useRef<HTMLInputElement>(null);
  const [header, setHeader] = useState<string>('');
  const [firstDigit, setFirstDigit] = useState<string>('');
  const [secondDigit, setSecondDigit] = useState<string>('');
  const [thirdDigit, setThirdDigit] = useState<string>('');
  const [arrowRight, setArrowRight] = useState<boolean>(false);
  const [arrowLeft, setArrowLeft] = useState<boolean>(false);

  const generateAgoraID = (): string => `${firstDigit}-${secondDigit}-${thirdDigit}`;

  const handleChange = (): void => {
    const agoraID = generateAgoraID();
    if (onChange !== undefined) {
      onChange(agoraID);
    }
  };

  const getValue = (
    value: string,
    currentFocus: HTMLInputElement,
    numberOfDigits: number = 4,
  ): string => {
    if (/^\d+$/.test(value) || value.length === 0) {
      const regexPattern = new RegExp(`^\\d{${numberOfDigits}}`);
      if (regexPattern.test(value) && currentFocus !== null) {
        currentFocus.focus();
      }
      return value;
    }
    return '';
  };

  const handleChangeFirstDigit = (e: ChangeEvent<HTMLInputElement>): void => {
    if (refSecond.current !== null) {
      const value = getValue(e.target.value, refSecond.current, 4);
      setFirstDigit(value);
    }
    e.preventDefault();
  };

  const handleKeyUp = (
    e: KeyboardEvent<HTMLInputElement>,
    current: HTMLInputElement | null,
    prev: HTMLInputElement | null,
    next: HTMLInputElement | null,
  ): void => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'ArrowLeft' && target.selectionStart === 0 && prev !== null) {
      if (arrowLeft) {
        prev.focus();
        setArrowLeft(false);
      } else {
        setArrowLeft(true);
      }
    }

    if (e.key === 'ArrowRight' && current !== null && target.selectionStart === current.value.length && next !== null) {
      if (arrowRight) {
        next.focus();
        setArrowRight(false);
      } else {
        setArrowRight(true);
      }
    }

    if (e.key === 'Backspace' && target.selectionStart === 0 && prev !== null) {
      prev.focus();
    }
  };

  const handleChangeSecondDigit = (e: ChangeEvent<HTMLInputElement>): void => {
    if (refThird.current !== null) {
      const value = getValue(e.target.value, refThird.current, 3);
      setSecondDigit(value);
    }
    e.preventDefault();
  };

  const handleChangeThirdDigit = (e: ChangeEvent<HTMLInputElement>): void => {
    if (refThird.current !== null) {
      const value = getValue(e.target.value, refThird.current, 4);
      setThirdDigit(value);
    }
    e.preventDefault();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    if (e.target.selectionStart === e.target.value.length) {
      setArrowRight(true);
      setArrowLeft(true);
    }
  };

  const handleClickHeader = (e: MouseEvent<HTMLInputElement>): void => {
    setHeader('AC');
    if (
      refFirst.current !== null
      && (e.target === refFirst.current
        || e.target === refHeader.current)
    ) {
      refFirst.current.focus();
    }
    e.preventDefault();
  };

  const getClipboardData = (e: ClipboardEvent<HTMLInputElement>): string => (
    e.clipboardData.getData('text').replace(/\D/g, '')
  );

  const handlePasteFirstDigit = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const clipboardData = getClipboardData(e);
    const clipboardFirstDigit = `${firstDigit}${clipboardData.substring(0, 4 - firstDigit.length)}`;
    const clipboardSecondDigit = clipboardData.substring(4 - firstDigit.length, 7 - firstDigit.length);
    const clipboardThirdDigit = clipboardData.substring(7 - firstDigit.length, 11 - firstDigit.length);
    setFirstDigit(clipboardFirstDigit);
    if (clipboardSecondDigit.length > 0 && refSecond.current !== null) {
      setSecondDigit(clipboardSecondDigit);
      refSecond.current.focus();
    }
    if (clipboardThirdDigit.length > 0 && refThird.current !== null) {
      setThirdDigit(clipboardThirdDigit);
      refThird.current.focus();
    }
  };

  const handlePasteSecondDigit = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const clipboardData = getClipboardData(e);
    const clipboardSecondDigit = `${secondDigit}${clipboardData.substring(0, 3 - secondDigit.length)}`;
    const clipboardThirdDigit = clipboardData.substring(3 - secondDigit.length, 7 - secondDigit.length);
    setSecondDigit(clipboardSecondDigit);
    if (clipboardThirdDigit.length > 0 && refThird.current !== null) {
      setThirdDigit(clipboardThirdDigit);
      refThird.current.focus();
    }
  };

  const handlePasteThirdDigit = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const clipboardData = getClipboardData(e);
    const clipboardThirdDigit = `${thirdDigit}${clipboardData.substring(0 - thirdDigit.length, 4 - thirdDigit.length)}`;
    setThirdDigit(clipboardThirdDigit);
  };

  useEffect(() => {
    handleChange();
  }, [header, firstDigit, secondDigit, thirdDigit]);

  useEffect(() => () => {
    setHeader('');
    setFirstDigit('');
    setSecondDigit('');
    setThirdDigit('');
    setArrowLeft(false);
    setArrowRight(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <label htmlFor='first-digit' className={styles.label}>{label}</label>
        <span className={styles['input-header']}>AC</span>
        -
        <input
          type='tel'
          id='first-digit'
          ref={refFirst}
          value={firstDigit}
          onChange={handleChangeFirstDigit}
          onClick={handleClickHeader}
          onFocus={handleFocus}
          onPaste={handlePasteFirstDigit}
          onKeyUp={(e) => handleKeyUp(e, refFirst.current, null, refSecond.current)}
          className={`${styles.input} ${styles['input-four-digit']}`}
          placeholder='XXXX'
          maxLength={4}
        />
        -
        <input
          type='tel'
          id='second-digit'
          ref={refSecond}
          value={secondDigit}
          onChange={handleChangeSecondDigit}
          onClick={handleClickHeader}
          onFocus={handleFocus}
          onPaste={handlePasteSecondDigit}
          onKeyUp={(e) => handleKeyUp(e, refSecond.current, refFirst.current, refThird.current)}
          className={`${styles.input} ${styles['input-three-digit']}`}
          placeholder='XXX'
          maxLength={3}
        />
        -
        <input
          type='tel'
          id='second-digit'
          ref={refThird}
          value={thirdDigit}
          onChange={handleChangeThirdDigit}
          onClick={handleClickHeader}
          onFocus={handleFocus}
          onPaste={handlePasteThirdDigit}
          onKeyUp={(e) => handleKeyUp(e, refThird.current, refSecond.current, null)}
          className={`${styles.input} ${styles['input-four-digit']}`}
          placeholder='XXXX'
          maxLength={4}
        />
      </div>
    </div>
  );
}

AgoraIdField.defaultProps = {
  onChange: undefined,
};

AgoraIdField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default AgoraIdField;
