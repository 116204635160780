export const countryName = ((codeISO: string, locale: string) => {
  try {
    const countryNameLocale = new Intl.DisplayNames([locale], { type: 'region' });

    return countryNameLocale.of(codeISO) || '';
  } catch {
    return codeISO;
  }
});

export const countryNameToSave = ((codeISO: string) => {
  try {
    const countryNameEn = new Intl.DisplayNames(['en'], { type: 'region' });
    const countryNameFr = new Intl.DisplayNames(['fr'], { type: 'region' });

    if (codeISO === 'CH') {
      return countryNameFr.of(codeISO) || '';
    }

    return countryNameEn.of(codeISO) || '';
  } catch {
    return codeISO;
  }
});
