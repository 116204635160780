import { atom, selector } from 'recoil';
import { UserRole } from '../api/institutionProxy';

type UserStatusInInstitution = {
  institution_affiliated: boolean,
  pre_registered?: boolean,
  roles?: Array<UserRole>,
  locale?: string,
}

export type UserToRegister = {
  status: UserStatusInInstitution,
};

export const userToRegisterState = atom<UserToRegister | undefined>({
  key: 'UserToRegister',
  default: undefined,
});

export const seletorUserToRegister = selector({
  key: 'currentUserToRegister',
  get: ({ get }) => get(userToRegisterState),
});

export const seletorUserToRegisterIsStaffOnly = selector({
  key: 'userToRegisterIsStaffOnly',
  get: ({ get }) => {
    const userToRegister = get(userToRegisterState);

    return userToRegister?.status.roles !== undefined
      && userToRegister.status.roles.length === 1
      && userToRegister.status.roles[0] === 'personnel';
  },
});
