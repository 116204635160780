import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@agora-care/ui-components';
import styles from './InputArrayItem.module.scss';

interface Props {
  value: string,
  disabled: boolean,
  className: string,
  onRemove(): void,
}

function InputArrayItem(props: Props): JSX.Element {
  const {
    value, disabled, className, onRemove,
  } = props;
  const { t } = useTranslation();

  const handleRemove = () => {
    if (onRemove !== undefined) {
      onRemove();
    }
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <span>{value}</span>
      <Button
        variant='text'
        color='error'
        size='small'
        onClick={handleRemove}
        disabled={disabled}
      >
        { t('input.remove') }
      </Button>
    </div>
  );
}

InputArrayItem.defaultProps = {
  disabled: false,
  className: '',
  onRemove: undefined,
};

InputArrayItem.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onRemove: PropTypes.func,
};

export default InputArrayItem;
