interface EncodedQueries extends Array<string>{}
export interface QueriesToEncode {
  [key: string]: Array<string> | string | number | boolean,
}

function getObjectParameters(queries: QueriesToEncode): EncodedQueries {
  const tabQueries: EncodedQueries = [];
  Object.keys(queries).forEach((key) => {
    const queryValue = queries[key];
    if (!Array.isArray(queryValue) && queries[key] !== '') {
      tabQueries.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryValue)}`);
    } else if (Array.isArray(queryValue)) {
      queryValue.forEach((value: string) => {
        tabQueries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
    }
  });
  return tabQueries;
}

export const getFormData = (queries: QueriesToEncode): string => {
  const tabQueries: EncodedQueries = getObjectParameters(queries);
  const stringQueries = tabQueries.length > 0 ? `${tabQueries.join('&')}` : '';
  return stringQueries;
};

export const getQueriesParameters = (queries: QueriesToEncode): string => {
  const stringQueries: string = getFormData(queries);
  return stringQueries.length > 0 ? `?${stringQueries}` : stringQueries;
};
