import PropTypes, { InferProps } from 'prop-types';
import drawerStyles from './Drawer.module.scss';

function Drawer(props: InferProps<typeof Drawer.propTypes>) {
  const { children } = props;

  return (
    <div className={drawerStyles.wrapper}>
      {children}
    </div>
  );
}

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Drawer;
