import { Loader } from '@agora-care/ui-components';
import styles from './PageLoader.module.scss';

function PageLoader() {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  );
}

export default PageLoader;
