import { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Form from 'components/layout/form/Form';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import DisplayInfo from 'components/displayInfo/DisplayInfo';
import Message from 'components/message/Message';
import { countryName } from 'utils/country';
import { Button } from '@agora-care/ui-components';
import ViewUser from '../user/ViewUser';
import {
  UserRequest, generateMailingAddressISO,
} from '../../api/user';
import { enrollUser } from '../../api/institutionProxy';
import { UserRegistration } from './UserRegistration';
import ContentButton from '../content/ContentButton';
import Title from '../content/Title';
import postTransferRequest from '../../api/transferRequest';
import EnrollmentForm, { UserEnroller } from '../../services/EnrollmentForm';
import { DisabledKeys } from './Registration';
import { seletorUserToRegisterIsStaffOnly } from '../../state/userToRegister';
import ADDRESS_NO_EMAIL from '../../constants/email';

interface Props {
  agoraID: string,
  institutionID: string,
  user: UserRegistration,
  userEnroller: UserEnroller,
  disabledKeys: DisabledKeys,
  onUpdate(): void,
  onCancel(): void,
  onSubmit(): void,
}

function RegistrationValidation(props: Props) {
  const {
    agoraID, institutionID, user, userEnroller, disabledKeys, onUpdate, onCancel, onSubmit,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const userToRegisterIsStaffOnly = useRecoilValue(seletorUserToRegisterIsStaffOnly);
  const enrollmentForm = useMemo(() => new EnrollmentForm('fr-CH'), []);

  const handleUpdate = (): void => {
    if (onUpdate !== undefined) {
      onUpdate();
    }
  };

  const handleCancel = () => {
    if (onCancel !== undefined) {
      onCancel();
    }
  };

  const handlePrint = (): void => {
    enrollmentForm.createPDF({
      ...user,
      agoraID,
      userEnroller,
      institutions: user.institutionsPatient.map((institution) => (
        institution.institutionName
      )),
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      if (
        window.authService !== undefined
        && window.tokenService !== undefined
        && institutionID !== undefined
      ) {
        setLoading(true);
        setError(false);
        const currentUser = await window.authService.getUser();
        const staffToken = await window.authService.getAccessToken();
        const delegateToken = await window.tokenService.getToken(agoraID);
        if (currentUser !== null && currentUser.profile.agora_id !== undefined) {
          const mailingAddress = generateMailingAddressISO(user);
          let newUser: UserRequest = {
            email: disabledKeys.includes('email') ? ADDRESS_NO_EMAIL : user.email,
            first_name: user.firstName.trim(),
            last_name: user.lastName.trim(),
            mobile_number: user.mobileNumber,
            date_of_birth: user.birthDate,
            mailing_address: mailingAddress.trim(),
            locale: user.locale,
            validation_type: user.validationType,
          };

          if (!userToRegisterIsStaffOnly) {
            newUser = {
              ...newUser,
              institutions: user.institutionsPatient.map((institution) => institution.institutionID),
            };
          }

          if (
            !disabledKeys.includes('pids')
            && !userToRegisterIsStaffOnly
          ) {
            newUser = {
              ...newUser,
              institutionsPIDs: [{
                institution: institutionID,
                pids: user.pids,
              }],
            };
          }
          if (delegateToken !== undefined && staffToken.length > 0) {
            await enrollUser(newUser, agoraID, institutionID, staffToken);
            if (!disabledKeys.includes('pids')
                && !userToRegisterIsStaffOnly
            ) {
              await postTransferRequest(agoraID, institutionID, 'day', delegateToken);
            }
            if (onSubmit !== undefined) {
              onSubmit();
            }
          }
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title
        title={userToRegisterIsStaffOnly ? t('staff.validation') : t('user.validation')}
      >
        <Button
          onClick={handlePrint}
          variant='outlined'
          color='neutral'
        >
          {t('registration.print')}
        </Button>
      </Title>
      <ViewUser
        agoraID={agoraID}
        user={{
          ...user,
          city: `${user.zipCode} ${user.city}`,
          country: countryName(user.country, user.locale),
          email: disabledKeys.includes('email') ? t('enrollmentform.no-email') : user.email,
          pids: disabledKeys.includes('pids') ? [t('enrollmentform.no-pids')] : user.pids,
        }}
      >
        <>
          <Title title={userToRegisterIsStaffOnly ? t('staff.affiliation') : t('user.affiliation')} />
          <CardWrapper>
            <DisplayInfo
              title={userToRegisterIsStaffOnly ? t('staff.affiliation') : t('user.affiliation')}
              value={user.institutionsPatient.map((institution) => (
                institution.institutionName
              ))}
            />
          </CardWrapper>
        </>
      </ViewUser>
      { error && <Message /> }
      <ContentButton>
        <Button
          disabled={loading}
          onClick={handleCancel}
          variant='text'
        >
          {t('registration.cancel')}
        </Button>
        <Button
          onClick={handleUpdate}
          variant='outlined'
          color='neutral'
        >
          {t('registration.update')}
        </Button>
        <Button
          type='submit'
          disabled={loading}
        >
          {t('registration.register')}
        </Button>
      </ContentButton>
    </Form>
  );
}

RegistrationValidation.defaultProps = {
  disabledKeys: [],
  onSubmit: undefined,
  onUpdate: undefined,
  onCancel: undefined,
};

RegistrationValidation.propTypes = {
  agoraID: PropTypes.string.isRequired,
  institutionID: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    pid: PropTypes.string,
    pids: PropTypes.arrayOf(PropTypes.string),
    birthDate: PropTypes.string,
    mobileNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    locale: PropTypes.string,
    institutionsPatient: PropTypes.arrayOf(
      PropTypes.shape({
        institutionName: PropTypes.string,
        institutionID: PropTypes.string,
      }),
    ),
    validationType: PropTypes.string,
  }).isRequired,
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default RegistrationValidation;
