import NavButtonItem from 'components/navigation/navButton/NavButton';
import { ReactComponent as LogoutIcon } from 'icons/LogoutIcon.svg';
import { useTranslation } from 'react-i18next';

function LogoutButton() {
  const { t } = useTranslation();
  const handleLogout = (): void => {
    if (window.authService !== undefined) {
      window.authService.logout();
    }
  };

  const navigationField = {
    icon: <LogoutIcon />,
    name: t('header.logout'),
    isActive: false,
    onClick: handleLogout,
  };

  return (
    <NavButtonItem navigationField={navigationField} />
  );
}

export default LogoutButton;
