import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import FormAgora from './FormAgora';
import { userToRegisterState } from '../../state/userToRegister';
import styles from './LayoutFormAgora.module.scss';

function LayoutFormAgora(): JSX.Element {
  const setStateUserToRegister = useSetRecoilState(userToRegisterState);

  useEffect(() => {
    setStateUserToRegister(undefined);
  }, []);

  return (
    <div className={styles.wrapper}>
      <CardWrapper>
        <div className='form-agora-container'>
          <FormAgora />
        </div>
      </CardWrapper>
    </div>
  );
}

export default LayoutFormAgora;
