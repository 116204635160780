import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './Message.module.scss';

type MessageProps = {
  className?: string;
  type: 'error' | 'success' | 'warning' | 'info';
  message?: string | string[];
};

function Message(props: MessageProps) {
  const {
    className,
    type,
    message,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={`${styles.wrapper} ${styles[type]} ${className}`}>
      <div className={styles.message}>
        {Array.isArray(message) ? (
          message.map((m) => (
            <p key={m}>{m}</p>
          ))
        ) : (
          <p>{message === undefined ? t('content.error') : message }</p>
        )}
      </div>
    </div>
  );
}

Message.defaultProps = {
  className: '',
  type: 'warning',
  message: undefined,
};

Message.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'error',
    'success',
    'warning',
    'info',
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Message;
