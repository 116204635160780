import { atom, selector } from 'recoil';
import { Institution } from '../api/institutionProxy';

type InstitutionsObject = {
  [institutions: string]: Institution,
};

const filteredInstitutions = [
  '16-0895-249', // Centre Médical de Terre Bonne
  '22-7416-567', // Unilabs
  '33-5655-767', // John McManus
  '36-8271-458', // Permanence Médico-Cgurzgucake Rond-Point Plainpalais
  '67-1720-647', // Centre Jean-Violette
  '67-3577-284', // GHOL
  '75-2959-544', // Adesso
  '82-6694-267', // UCI Health
  '86-3946-547', // Arsanté
  '92-7835-975', // Centre d'Imagerie Médicale d'Eysin
  '96-9773-423', // 3R
];

export const institutionsState = atom<Institution[]>({
  key: 'Institutions',
  default: [],
});

export const selectorInstitutionsState = selector({
  key: 'allInstitutionState',
  get: ({ get }) => get(institutionsState),
});

export const selectorInstitutionsObjectState = selector({
  key: 'allInstitutionObjectState',
  get: ({ get }) => {
    const institutions = get(institutionsState);

    const object: InstitutionsObject = {};

    institutions.forEach((institution) => {
      object[institution.institutionID] = institution;
    });

    return object;
  },
});

export const selectorFiltererInstitutionsState = selector({
  key: 'filteredInstitutionsState',
  get: ({ get }) => {
    const institutionsList = get(institutionsState);

    return institutionsList.filter((institution) => filteredInstitutions.includes(institution.institutionID));
  },
});
