import PropTypes, { InferProps } from 'prop-types';
import styles from './DisplayInfo.module.scss';

function DisplayInfo(props: InferProps<typeof DisplayInfo.propTypes>) {
  const {
    title, value,
  } = props;

  return (
    <div className={styles.wrapper}>
      <span className={styles.secondary}>
        {title}
      </span>
      {Array.isArray(value) ? (
        <div className={styles.value}>
          {value.length > 0 ? value.map((v) => (
            <div key={v}>{v}</div>
          )) : '-'}
        </div>
      ) : (
        <span className={styles.value}>{value || '-'}</span>
      )}
    </div>
  );
}

DisplayInfo.defaultProps = {
  value: undefined,
};

DisplayInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default DisplayInfo;
