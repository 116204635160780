import PropTypes from 'prop-types';
import styles from './InputContent.module.scss';

type Props = {
  className: string,
  children: JSX.Element,
}

function InputContent(props: Props): JSX.Element {
  const { className, children } = props;

  return (
    <div
      className={`${className} ${styles.content}`}
    >
      {children}
    </div>
  );
}

InputContent.defaultProps = {
  className: '',
};

InputContent.propTypes = {
  className: PropTypes.string,
};

export default InputContent;
