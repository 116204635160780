import {
  MouseEvent, KeyboardEvent, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import ContentButton from 'components/content/ContentButton';
import Message from 'components/message/Message';
import { Button } from '@agora-care/ui-components';
import { UserRegistered } from './UserRegistred';
import Content from '../content/Content';
import Title from '../content/Title';
import ViewUser from './ViewUser';
import postTransferRequest, { TypeTransferRequest } from '../../api/transferRequest';
import styles from './RegisteredViewUser.module.scss';

interface Props {
  agoraID: string,
  institutionID: string,
  user: UserRegistered,
}

function RegisteredViewUser(props: Props): JSX.Element {
  const {
    agoraID, institutionID, user,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleValidClick = async (e: MouseEvent | KeyboardEvent, typeTransferRequest: TypeTransferRequest | null) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      if (window.tokenService !== undefined && window.authService !== undefined && agoraID !== undefined) {
        const delegateToken = await window.tokenService.getToken(agoraID);
        const currentUser = await window.authService.getUser();
        if (currentUser !== null && delegateToken !== undefined) {
          if (typeTransferRequest !== null) {
            await postTransferRequest(agoraID, institutionID, typeTransferRequest, delegateToken);
          }
          navigate('/');
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  const handleUpdateClick = () => {
    navigate(`/${agoraID}/edit`);
  };

  return (
    <Content>
      <div className={styles.wrapper}>
        <ViewUser
          agoraID={agoraID}
          user={user}
        >
          <>
            <Title title={t('user.sendexams')} />
            <CardWrapper>
              <ContentButton>
                <Button
                  variant='outlined'
                  color='neutral'
                  onClick={(e) => handleValidClick(e, 'day')}
                  disabled={loading}
                >
                  {t('user.dayexam')}
                </Button>
                <Button
                  variant='outlined'
                  color='neutral'
                  onClick={(e) => handleValidClick(e, 'full_history')}
                  disabled={loading}
                >
                  {t('user.fullhistoryexam')}
                </Button>
              </ContentButton>
            </CardWrapper>
          </>
        </ViewUser>
        <ContentButton>
          <Button
            variant='text'
            color='neutral'
            onClick={() => navigate('/')}
            disabled={loading}
          >
            {t('user.viewback')}
          </Button>
          <Button
            variant='outlined'
            color='neutral'
            onClick={handleUpdateClick}
            disabled={loading}
          >
            {t('user.viewupdate')}
          </Button>
          <Button
            onClick={(e) => handleValidClick(e, null)}
            disabled={loading}
          >
            {t('user.viewfinish')}
          </Button>
        </ContentButton>
        { error && <Message /> }
      </div>
    </Content>
  );
}

RegisteredViewUser.propTypes = {
  agoraID: PropTypes.string.isRequired,
  institutionID: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    pids: PropTypes.arrayOf(PropTypes.string),
    birthDate: PropTypes.string,
    mobileNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    locale: PropTypes.string,
    institutions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default RegisteredViewUser;
